export default {
  en: {
    routes_plural: 'Routes',
    createRouteFailed: 'Create route failed',
    createRouteSuccess: 'Create route success',
    updateRouteFailed: 'Update route failed',
    updateRouteSuccess: 'Update route success',
    protocols_plural: 'Protocols',
    createProtocolFailed: 'Create protocol failed',
    createProtocolSuccess: 'Create protocol success',
    updateProtocolFailed: 'Update protocol failed',
    updateProtocolSuccess: 'Update protocol success',
    management: 'Management',
    name: 'Name',
    acceptanceCriterias_plural: 'Acceptance criterias',
    customers_plural: 'Customers',
    edlms_plural: 'Edlms',
    laneTypes_plural: 'Lane types',
    packagings_plural: 'Packagings',
    productPackagings_plural: 'Product packagings',
    productPhases_plural: 'Product phases',
    products_plural: 'Products',
    transitTypes_plural: 'Transit types',
    transportModes_plural: 'Transport modes',
    createRoute: 'Create route',
    cancel: 'Cancel',
    save: 'Save',
    next: 'Next',
    back: 'Back',
    routeInformation: 'Route information',
    routeInformationDescription: 'Basic route configuration',
    routeSteps: 'Route steps',
    routeStepsDescription: 'The different steps of a route',
    timeZone: 'Time zone',
    transportMode: 'Transport mode',
    laneType: 'Lane type',
    hasForwarder: 'Does the route has a forwarder?',
    forwarder: 'Forwarder',
    orgin: 'Origin',
    originLocation: 'Origin location',
    departure: 'Departure',
    departureLocation: 'Departure location',
    destinationLocation: 'Destination location',
    type: 'Type',
    arrivalLocation: 'Arrival location',
    addTransit: 'Add transit',
    hasHandlingAgent: 'Has handling agent',
    hasRampAgent: 'Has ramp agent',
    agent: 'Agent',
    noTransitsDefined: 'No transits defined',
    nameEmptyErr: 'Please enter a name',
    timeZoneEmptyErr: 'Please select a time zone',
    transportModeEmptyErr: 'Please select a transport mode',
    laneTypeEmptyErr: 'Please select a lane type',
    criticalPointsDocumentEmptyErr: 'Please upload a critical points document',
    originEmptyErr: 'Please enter an origin location',
    departureEmptyErr: 'Please enter an departure location',
    destinationEmptyErr: 'Please enter an destination',
    typeEmptyErr: 'Please select a type',
    arrivalEmptyErr: 'Please enter an arrival location',
    route: 'Route',
    configureRoute: 'Configure route',
    testShipments: 'Test shipments',
    configureTestShipments: 'Define test shipments',
    edlms: 'Edlms',
    configureEdlms: 'Define Edlms',
    criterias: 'Criterias',
    defineCriterias: 'Define criterias',
    createProtocol: 'Create protocol',
    customer: 'Customer',
    product: 'Product',
    packaging: 'Packaging',
    fullName: 'Full name',
    emailAddress: 'E-mail address',
    phoneNumber: 'Phone number',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    noTestShipmentsDefined: 'No test shipments defined',
    addTestShipment: 'Add test shipment',
    month: 'Month',
    selectAMonth: 'Select a month',
    noEdlmsDefined: 'No edlms defined',
    addEdlm: 'Add edlm',
    startDelay: 'Start delay (in minutes)',
    measuringInterval: 'Measuring interval (in minutes)',
    alarmMaximumTemperature: 'Alarm maximum temperature (in °C.)',
    alarmMinimumTemperature: 'Alarm minium temperature (in °C.)',
    routeIdEmptyErr: 'Please select a route',
    customerIdEmptyErr: 'Please select a customer',
    productIdEmptyErr: 'Please select a product',
    packagingIdEmptyErr: 'Please select a packaging',
    contactPersonNameEmptyErr: 'Please enter a name',
    contactPersonEmailAddressEmptyErr: 'Please enter an e-mail address',
    contactPersonMobileNumberEmptyErr: 'Please enter a phone number',
    contactPersonCompanyEmptyErr: 'Please enter a company name',
    configureCriterias: 'Configure criterias',
    agents_plural: 'Agents',
    forwarders_plural: 'Forwarders',
    productTypes_plural: 'Product types',
    streetAndNumber: 'Street & number',
    city: 'City',
    zipCode: 'Zip code',
    countryCode: 'Country code',
    vatNumber: 'Vat number',
    country: 'Country',
    manufacturer: 'Manufacturer',
    referenceNumber: 'Reference number',
    typeId: 'Product type',
    phaseId: 'Product phase',
    packagingId: 'Product packaging',
    routeResponsibilities: 'Route responsibilities',
    routeResponsibilitiesDescription: 'Define responsibilities & actions',
    responsibility: 'Responsibility',
    action: 'Action',
    addResponsibility: 'Add responsibility',
    addAction: 'Add action',
    noResponsibilitiesDefined: 'No responsibilities defined',
    noActionsDefined: 'No actions defined',
    transportModeIdEmptyErr: 'Please select a transport mode',
    laneTypeIdEmptyErr: 'Please select a lane type',
    originNameEmptyErr: 'Please enter a (company) name',
    originStreetAndNumberEmptyErr: 'Please enter a street & number',
    originZipCodeEmptyErr: 'Please enter a zip code',
    originCityEmptyErr: 'Please enter a city',
    originCountryEmptyErr: 'Please enter a country',
    departurePortEmptyErr: 'Please enter a port',
    departureStreetAndNumberEmptyErr: 'Please enter a street & number',
    departureZipCodeEmptyErr: 'Please enter a zip code',
    departureCityEmptyErr: 'Please enter a city',
    departureCountryEmptyErr: 'Please enter a country',
    arrivalPortEmptyErr: 'Please enter a port',
    arrivalStreetAndNumberEmptyErr: 'Please enter a street & number',
    arrivalZipCodeEmptyErr: 'Please enter a zip code',
    arrivalCityEmptyErr: 'Please enter a city',
    arrivalCountryEmptyErr: 'Please enter a country',
    destinationNameEmptyErr: 'Please enter a (company) name',
    destinationStreetAndNumberEmptyErr: 'Please enter a street & number',
    destinationZipCodeEmptyErr: 'Please enter a zip code',
    destinationCityEmptyErr: 'Please enter a city',
    destinationCountryEmptyErr: 'Please enter a country',
    typeIdEmptyErr: 'Please select a type',
    monthEmptyErr: 'Please select a month',
    company: 'Company',
    idEmptyErr: 'Select an EDLM type',
    measuringIntervalEmptyErr: 'Enter a measuring interval',
    startDelayEmptyErr: 'Enter a start delay',
    alarmMaximumTemperatureEmptyErr: 'Enter a maximum temperature to trigger an alarm',
    noCriteriasDefined: 'No acceptance criterias defined yet',
    addCriteria: 'Add acceptance criteria',
    generateProtocolDocument: 'Generate protocol document',
    generatingProtocolDocument: 'Generating protocol document',
    couldntGenerateProtocolDocument: 'Could not generate protocol document',
    origin: 'Origin',
    destination: 'Destination',
    protocolForRoute: 'Protocol for route',
    areYouSureRemoveRoute: 'Are you sure you want to remove this route?',
    areYouSureRemoveProtocol: 'Are you sure you want to remove this protocol?',
    criteria: 'Criteria',
    inputData: 'Import data',
    nameOrCompanyName: '(Company) name',
    port: 'Port',
    portEmptyErr: 'Please enter a port',
    dashboards: 'Dashboards',
    configuration: 'Configuration',
    period: 'Period',
    selectAPeriod: 'Select a period',
    noTopicsDefined: 'No topics defined',
    addTopic: 'Add topic',
    topic: 'Topic',
    enterATopic: 'Please enter a topic',
    topicEmptyErr: 'Please enter a topic',
    introduction: 'Introduction',
    writeAnIntroduction: 'Write an introduction',
    conclusion: 'Conclusion',
    writeAConclusion: 'Write a conclusion',
    createCustomerFailed: 'Could not create customer',
    createCustomerSuccess: 'Customer created',
    updateCustomerFailed: 'Could not update customer',
    updateCustomerSuccess: 'Customer updated',
    createCustomer: 'Create customer',
    getCustomerFailed: 'Could not get customer',
    createEdlmFailed: 'Could not create edlm',
    createEdlmSuccess: 'Edlm created',
    updateEdlmFailed: 'Could not update edlm',
    updateEdlmSuccess: 'Edlm updated',
    getEdlmFailed: 'Could not get edlm',
    createEdlm: 'Create edlm',
    getProductFailed: 'Could not get product',
    createProductFailed: 'Could not create product',
    createProductSuccess: 'Product created',
    updateProductFailed: 'Could not update product',
    updateProductSuccess: 'Product updated',
    createProduct: 'Create product',
    storageTemperature: 'Storage temperature (in c°)',
    transportTemperature: 'Transport temperature (in c°)',
    transportTemperatureEmptyErr: 'Please enter the transport temperature',
    storageTemperatureEmptyErr: 'Please enter the storage temperature',
    referenceNumberEmptyErr: 'Please enter a reference number',
    phaseIdEmptyErr: 'Please select a phase',
    manufacturerEmptyErr: 'Please enter a manufacturer',
    countryCodeEmptyErr: 'Please select a country',
    createAgentSuccess: 'Agent created',
    createAgentFailed: 'Could not create agent',
    updateAgentFailed: 'Could not update agent',
    updateAgentSuccess: 'Agent updated',
    getAgentFailed: 'Could not get agent',
    createAgent: 'Create agent',
    createForwarderFailed: 'Could not create forwarder',
    createForwarderSuccess: 'Forwarder created',
    getForwarderFailed: 'Could not get forwarder',
    updateForwarderFailed: 'Could not update forwarder',
    updateForwarderSuccess: 'Forwarder updated',
    createForwarder: 'Create forwarder',
    testShipments_plural: 'Test shipments for protocol with route "{{protocolName}}"',
    noTestShipmentsFound: 'No test shipments found',
    createTestShipment: 'Create test shipment',
    loadingDocument: 'Loading document',
    edlmDataDocument: 'Edlm data (csv)',
    createTestShipmentFailed: 'Could not create test shipment',
    createTestShipmentSuccess: 'Test shipment created',
    updateTestShipmentFailed: 'Could not update test shipment',
    updateTestShipmentSuccess: 'Test shipment updated',
    getTestShipmentFailed: 'Could not get test shipment',
    createTestShipmentForProtocol:
      'Create test shipment for protocol with route "{{protocolName}}"',
    reference: 'Reference',
    fileUploadFailedAndAbortedCreateTestShipment:
      'Could not create test shipment because file upload failed',
    fileUploadFailedAndAbortedUpdateTestShipment:
      'Could not update test shipment because file upload failed',
    updateTestShipmentForProtocol: 'Update test shipment for protocol with route {{protocolName}}',
    areYouSureRemoveTestShipment: 'Are you sure you want to remove this test shipment?',
    serialNumbers: 'Serial numbers',
    addSerialNumber: 'Add serial number',
    addEdlmInfo: 'Add edlm info',
    referenceEmptyErr: 'Please enter a reference',
    download: 'Download',
    charts: 'Charts',
    dataOfTestShipment: 'Data of test shipment',
    viewChartsAndData: 'View data & charts',
    measuredTemperature: 'Measured temperature',
    exportChart: 'Export chart',
    toPdf: 'To pdf',
    toPng: 'To png',
    minProductTemperature: 'Min. product temperature',
    maxProductTemperature: 'Max. product temperature',
    minProductTransportTemperature: 'Min. product transport temperature',
    maxProductTransportTemperature: 'Max. product transport temperature',
    minProductTemperatureEmptyErr: 'Please enter a temperature',
    maxProductTemperatureEmptyErr: 'Please enter a temperature',
    minProductTransportTemperatureEmptyErr: 'Please enter a temperature',
    maxProductTransportTemperatureEmptyErr: 'Please enter a temperature',
    milestones: 'Milestones',
    noMilestonesDefined: 'No milestones defined',
    addMilestone: 'Add milestone',
    companyName: 'Company name',
    iata: 'IATA',
    location: 'Location',
    timestamp: 'Timestamp',
    enterLocationOrIata: 'Either enter a location or IATA code',
    timestampEmptyErr: 'Please enter or select a timestamp',
    companyNameEmptyErr: 'Please enter a company name',
    removeMilestone: 'Remove milestone',
    milestonesForTestShipment: 'Milestones for test shipment "{{reference}}"',
    productOrTransportTemperature: 'Product or transport temperature',
    selectASerialNumber: 'Select a serial number',
    attachedOn: 'Attached on',
    serialNumber: 'Serial number',
    loadingChart: 'Loading chart',
    export: 'Export',
    refineChart: 'Refine the chart:',
    temperatureType: 'EDLM attached on',
    periodEmptyErr: 'Please select a period',
    testShipmentAlreadyExists: 'A testshipment with this month already exists',
    noEdlmData: 'No data found',
    noEdlmDataInfo:
      'Please upload some EDLM data for the serial numbers defined in your test shipment',
    streetAndNumberEmptyErr: 'Please enter a street and number',
    vatNumberEmptyErr: 'Please enter a VAT number',
    cityEmptyErr: 'Please enter a city',
    zipCodeEmptyErr: 'Please enter a zip code',
    alarmMinTemperatureEmptyErr: 'Please enter a alarm min. temperature',
    alarmMaxTemperatureEmptyErr: 'Please enter a alarm max. temperature',
    stepEmptyErr: 'Please enter a responsibility',
    getProtocolFailed: "Couldn't get protocol",
    getRouteFailed: "Couldn't get route",
    createProductPackagingFailed: "Couldn't create product packaging",
    createProductPackagingSuccess: 'Product packaging created',
    getProductPackagingFailed: "Couldn't get product packaging",
    updateProductPackagingFailed: "Couldn't update product packaging",
    updateProductPackagingSuccess: 'Product packaging updated',
    createProductPackaging: 'Create product packaging',
    companyEmptyErr: 'Please enter a company',
    urlEmptyErr: 'Please enter a URL',
    timezoneEmptyErr: 'Please select a timezone',
    removeEdlm: 'Remove edlm',
    minTransportTemperature: 'Minimum transport temperature',
    maxTransportTemperature: 'Maximum transport temperature',
    minTransportTemperatureEmptyErr: 'Please enter a minimum transport temperature',
    maxTransportTemperatureEmptyErr: 'Please enter a maximum transport temperature',
    edlm: 'Edlm',
    transport: 'Transport',
  },
};
