import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'nallian-shared-ui/lib/components';
import { TextAreaFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { GridColumn } from '../../../app/styles';

const CriteriaField = ({ index, submitProcessing, field }) => {
  const { t } = useTranslation();

  // const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <>
      <GridColumn noPaddingBottom>
        <Field
          fluid
          label={index <= 0 && t('criteria')}
          name={`${field}.criteria`}
          valueComponent={TextAreaFieldValueComponent}
          disabled={submitProcessing}
        />
      </GridColumn>
    </>
  );
};

CriteriaField.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  field: PropTypes.array.isRequired,
};

export default CriteriaField;
