import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import get from 'lodash/get';
import { Field, Grid, Divider } from 'nallian-shared-ui/lib/components';
import {
  SelectFieldValueComponent,
  InputFieldValueComponent,
  CheckBoxFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import { GridColumn } from '../../../app/styles';
import apiRoutes from '../../../../app/api/routes';
import { FORM_NAME } from '../../constants';

const TransitFields = ({
  submitProcessing,
  dataLoading,
  field,
  transitTypesFetch,
  agentsFetch,
}) => {
  const { t } = useTranslation();

  // const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  const showHandlingAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.${field}.handlingAgent.enabled`, false)
  );

  const showRampAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.${field}.rampHandling.enabled`, false)
  );

  const typeOptions = useMemo(
    () =>
      (transitTypesFetch.value || []).map(type => ({
        key: type._id,
        value: type._id,
        text: type.name,
      })),
    [transitTypesFetch.value]
  );

  const agentOptions = useMemo(
    () =>
      (agentsFetch.value || []).map(agent => ({
        key: agent._id,
        value: agent._id,
        text: agent.name,
      })),
    [agentsFetch.value]
  );

  return (
    <>
      <Grid stretched columns={2}>
        <Grid.Column>
          <Field
            fluid
            search
            clearable
            label={t('type')}
            name={`${field}.typeId`}
            valueComponent={SelectFieldValueComponent}
            options={typeOptions}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            fluid
            label={t('port')}
            name={`${field}.port`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <GridColumn>
          <Field
            fluid
            toggle
            label={t('hasHandlingAgent')}
            name={`${field}.handlingAgent.enabled`}
            valueComponent={CheckBoxFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </GridColumn>
        <GridColumn>
          {showHandlingAgentField && (
            <Field
              fluid
              search
              clearable
              label={t('agent')}
              name={`${field}.handlingAgent.id`}
              options={agentOptions}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          )}
        </GridColumn>
        <GridColumn>
          <Field
            fluid
            toggle
            label={t('hasRampAgent')}
            name={`${field}.rampHandling.enabled`}
            valueComponent={CheckBoxFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </GridColumn>
        <GridColumn>
          {showRampAgentField && (
            <Field
              fluid
              search
              clearable
              label={t('agent')}
              name={`${field}.rampHandling.id`}
              options={agentOptions}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          )}
        </GridColumn>
      </Grid>
      <Divider />
    </>
  );
};

TransitFields.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
  transitTypesFetch: PropTypes.object.isRequired,
  agentsFetch: PropTypes.object.isRequired,
};

export default compose(
  lookupService({
    resource: 'transitTypes',
    url: apiRoutes.transitTypes(),
  }),
  lookupService({
    resource: 'agents',
    url: apiRoutes.agents(),
  })
)(TransitFields);
