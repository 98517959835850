import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const ProductsScene = () => (
  <>
    <Route exact path="/management/products/:id?" menuName="products_plural" component={Overview} />
  </>
);

export default ProductsScene;
