import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'nallian-shared-ui/lib/components';

const MilestonesOverviewField = ({ value, item }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/protocol/${item.protocolId}/test-shipments/milestones/${item._id}`}>
      {value?.length}&nbsp;{t('milestones')}
    </Link>
  );
};

MilestonesOverviewField.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.array,
};
MilestonesOverviewField.defaultProps = { value: [] };

export default MilestonesOverviewField;
