export const PROTOCOLS_RESOURCE = 'protocols';
export const PROTOCOL_RESOURCE = 'protocol';
export const CREATE_PROTOCOL_RESOURCE = 'createProtocol';
export const UPDATE_PROTOCOL_RESOURCE = 'updateProtocol';
export const REMOVE_PROTOCOL_RESOURCE = 'removeProtocol';

export const INFINITE_SCROLL = { loader: 'blocked' };
export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
};

export const FORM_NAME = 'protocolForm';

export const PERIOD_OPTIONS = [
  {
    key: 'Warm',
    value: 'Warm',
    text: 'Warm',
  },
  {
    key: 'Cold',
    value: 'Cold',
    text: 'Cold',
  },
];

export const MONTH_OPTIONS = [
  {
    key: '0',
    value: '0',
    text: 'January',
  },
  {
    key: '1',
    value: '1',
    text: 'February',
  },
  {
    key: '2',
    value: '2',
    text: 'March',
  },
  {
    key: '3',
    value: '3',
    text: 'April',
  },
  {
    key: '4',
    value: '4',
    text: 'May',
  },
  {
    key: '5',
    value: '5',
    text: 'June',
  },
  {
    key: '6',
    value: '6',
    text: 'July',
  },
  {
    key: '7',
    value: '7',
    text: 'August',
  },
  {
    key: '8',
    value: '8',
    text: 'September',
  },
  {
    key: '9',
    value: '9',
    text: 'October',
  },
  {
    key: '10',
    value: '10',
    text: 'November',
  },
  {
    key: '11',
    value: '11',
    text: 'December',
  },
];
