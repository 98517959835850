import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageTitle, PageContent, Flex, Link, Button } from 'nallian-shared-ui/lib/components';
import { Table, Params, Menu, Actions } from 'nallian-table-builder-ui';
import NewCustomer from './NewCustomer';
import EditCustomer from './EditCustomer';
import { INFINITE_SCROLL, CUSTOMERS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';
import Country from './table/Country';

const CustomersOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <PageTitle>{t('customers_plural')}</PageTitle>
      <PageContent>
        <NewCustomer open={id && id === 'add'} />
        <EditCustomer open={id && id !== 'add'} />
        <Flex>
          <Link to="/management/customers/add">
            <Button btnprimary icon="add" content={t('createCustomer')} />
          </Link>
        </Flex>
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={CUSTOMERS_RESOURCE}
          url={apiRoutes.customers()}
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url="/management/customers/:_id"
          />
          <Params.String name="name" width="11rem" noFilter grow />
          <Params.String name="streetAndNumber" width="11rem" noFilter grow />
          <Params.String name="city" width="11rem" noFilter grow />
          <Params.String name="zipCode" width="11rem" noFilter grow />
          <Params.Custom
            name="countryCode"
            label="country"
            width="11rem"
            content={Country}
            alignItems="center"
            noFilter
            grow
          />
          <Params.String name="vatNumber" width="11rem" noFilter grow />
          <Menu width="3.5rem" align="center" alignItems="center" />
        </Table>
      </PageContent>
    </>
  );
};

CustomersOverview.propTypes = {};

export default CustomersOverview;
