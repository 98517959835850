import reduxFetch from 'react-redux-fetch';
import { AGENT_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const AgentService = ComposedComponent =>
  reduxFetch([
    {
      resource: AGENT_RESOURCE,
      method: 'get',
      request: (id, rejectCallback, t) => ({
        url: apiRoutes.agents(id),
        meta: {
          rejectCallback,
          toaster: {
            onErrorMessage: t('getAgentFailed'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default AgentService;
