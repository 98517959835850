import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createAgentService from '../services/createAgentService';
import AgentForm from './AgentForm';

const EMPTY_AGENT = { name: '' };

const NewAgent = ({ open, dispatchCreateAgentPost, createAgentFetch }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body => dispatchCreateAgentPost(body, () => history.push('/management/agents'), undefined, t),
    [dispatchCreateAgentPost, t]
  );

  if (!open) return null;

  return (
    <AgentForm
      header={t('createAgent')}
      onSubmit={handleSubmit}
      submitProcessing={createAgentFetch.pending}
      returnRoute="/management/agents"
      cancelRoute="/management/agents"
      data={EMPTY_AGENT}
    />
  );
};

NewAgent.propTypes = {
  dispatchCreateAgentPost: PropTypes.func.isRequired,
  createAgentFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewAgent.defaultProps = { open: false };

export default createAgentService(NewAgent);
