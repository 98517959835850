import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const TransitTypesScene = () => (
  <>
    <Route
      exact
      path="/management/transit-types/:id?"
      menuName="transitTypes_plural"
      component={Overview}
    />
  </>
);

export default TransitTypesScene;
