import pickBy from 'lodash/pickBy';

const filterEmptyValues = v => v !== null && v !== undefined && v !== '';

export const mapFormToRoute = values => ({
  ...values,
  criticalPointsDocument: values.criticalPointsDocument?.name,
  forwarder: {
    ...pickBy(values.forwarder || {}, filterEmptyValues),
    enabled: Boolean(values?.forwarder?.enabled),
  },
  departure: {
    ...(values.departure || {}),
    handlingAgent: {
      ...pickBy(values?.departure?.handlingAgent || {}, filterEmptyValues),
      enabled: Boolean(values.departure?.handlingAgent?.enabled),
    },
    rampHandling: {
      ...pickBy(values.departure?.rampHandling || {}, filterEmptyValues),
      enabled: Boolean(values?.departure?.rampHandling?.enabled),
    },
  },
  arrival: {
    ...(values.arrival || {}),
    handlingAgent: {
      ...pickBy(values?.arrival?.handlingAgent || {}, filterEmptyValues),
      enabled: Boolean(values.arrival?.handlingAgent?.enabled),
    },
    rampHandling: {
      ...pickBy(values.arrival?.rampHandling || {}, filterEmptyValues),
      enabled: Boolean(values?.arrival?.rampHandling?.enabled),
    },
  },
  transits: (values?.transits || []).map(transit => ({
    ...transit,
    handlingAgent: {
      ...pickBy(transit.handlingAgent || {}, filterEmptyValues),
      enabled: Boolean(transit?.handlingAgent?.enabled),
    },
    rampHandling: {
      ...pickBy(transit.rampHandling || {}, filterEmptyValues),
      enabled: Boolean(transit?.rampHandling?.enabled),
    },
  })),
  responsibilities: (values?.responsibilities || []).map(responsibility => ({
    ...responsibility,
    actions: (responsibility.actions || [])
      .map(action => (action.action ? action : undefined))
      .filter(a => a),
  })),
  topics: (values?.topics || []).map(topic => ({
    ...topic,
  })),
});
