import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { history } from 'nallian-shared-ui/lib/config/history';
import { PageTitle, PageContent, Flex, Link } from 'nallian-shared-ui/lib/components';
import { Table, Params, Actions } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, TEST_SHIPMENTS_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';
import protocolService from '../../protocols/services/ProtocolService';
import NewTestShipment from './NewTestShipment';
import EditTestShipment from './EditTestShipment';
import MilestonesForm from './form/milestones/MilestonesForm';
import MilestonesOverviewField from './table/MilestonesOverviewField';
import Month from './table/Month';

const TestShipmentsOverview = ({ dispatchProtocolGet, protocolFetch }) => {
  const { t } = useTranslation();
  const { id, subResourceId, subResourceAction } = useParams();

  useEffect(() => {
    if (id) {
      dispatchProtocolGet(id, () => history.push('/protocols'), t);
    }
  }, [id, t, dispatchProtocolGet]);

  if (subResourceId === 'charts') return null;

  return (
    <>
      <PageTitle>
        <Flex alignItems="center">
          <Link to="/protocols">{t('protocols_plural')}</Link>
          &nbsp;/&nbsp;
          {t('testShipments_plural', { protocolName: protocolFetch.value?.route?.name })}
        </Flex>
      </PageTitle>
      <PageContent>
        <NewTestShipment
          protocol={protocolFetch.value}
          open={subResourceAction === 'add' && !subResourceId}
        />
        <EditTestShipment
          protocol={protocolFetch.value}
          open={subResourceId && subResourceAction === 'edit'}
        />
        {subResourceAction === 'milestones' && <MilestonesForm />}
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={TEST_SHIPMENTS_RESOURCE}
          url={apiRoutes.testShipments(id)}
          noResultsMessage={t('noTestShipmentsFound')}
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url={`/protocol/${id}/test-shipments/edit/:_id`}
          />
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="chart area"
            align="center"
            url={`/protocol/${id}/test-shipments/:_id/charts`}
          />
          <Params.String name="reference" width="11rem" noFilter grow />
          <Params.String name="period" width="11rem" noFilter grow />
          <Params.Custom content={Month} name="month" width="11rem" noFilter grow />
          <Params.Custom
            content={MilestonesOverviewField}
            label="milestones"
            name="milestones"
            width="11rem"
            noFilter
            grow
          />
        </Table>
      </PageContent>
    </>
  );
};

TestShipmentsOverview.propTypes = {
  dispatchProtocolGet: PropTypes.func.isRequired,
  protocolFetch: PropTypes.object.isRequired,
};

export default protocolService(TestShipmentsOverview);
