import reduxFetch from 'react-redux-fetch';
import { PRODUCTS_RESOURCE, CREATE_PRODUCT_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const CreateProductService = ComposedComponent =>
  reduxFetch([
    {
      resource: CREATE_PRODUCT_RESOURCE,
      method: 'post',
      request: (body, callback, rejectCallback, t) => ({
        url: apiRoutes.products(),
        body,
        meta: {
          callback,
          rejectCallback,
          addToOtherLists: [
            {
              resource: PRODUCTS_RESOURCE,
              idProp: '_id',
            },
          ],
          toaster: {
            onErrorMessage: t('createProductFailed'),
            onSuccessMessage: t('createProductSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default CreateProductService;
