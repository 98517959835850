import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import { showToaster } from 'nallian-shared-ui/lib/lib/showToaster';
import updateRouteService from '../services/UpdateRouteService';
import routeService from '../services/RouteService';
import routesService from '../services/RoutesService';
import RouteForm from './RouteForm';
import { mapFormToRoute } from '../helpers/mapFormToRoute';
import { uploadFile } from '../../app/helpers/uploadFile';

const EditRoute = ({
  open,
  dispatchUpdateRoutePut,
  updateRouteFetch,
  dispatchRouteGet,
  dispatchRoutesGet,
  routeFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [fetching, setFetching] = useState(false);

  const handleSubmit = useCallback(
    async body => {
      setFetching(true);
      if (body.criticalPointsDocument) {
        const uploadSuccess = await uploadFile(body.criticalPointsDocument);
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedUpdateRoute'));
          return false;
        }
      }
      dispatchUpdateRoutePut(
        id,
        mapFormToRoute(body),
        () => {
          dispatchRoutesGet();
          history.push(`/routes`);
          setFetching(false);
        },
        () => setFetching(false),
        t
      );

      return true;
    },
    [setFetching, id, dispatchUpdateRoutePut, dispatchRoutesGet, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchRouteGet(id, () => history.push('/routes'), t);
    }
  }, [id, open, t, dispatchRouteGet]);

  const defaultData = useMemo(
    () =>
      !routeFetch.pending && {
        ...(routeFetch.value || {}),
      },
    [routeFetch]
  );

  if (!open) return null;

  return (
    <RouteForm
      header={routeFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateRouteFetch.pending || fetching}
      returnRoute="/routes"
      cancelRoute="/routes"
      dataLoading={routeFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditRoute.propTypes = {
  dispatchRoutesGet: PropTypes.func.isRequired,
  dispatchUpdateRoutePut: PropTypes.func.isRequired,
  updateRouteFetch: PropTypes.object.isRequired,
  dispatchRouteGet: PropTypes.func.isRequired,
  routeFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditRoute.defaultProps = { open: false };

export default compose(routesService, routeService, updateRouteService)(EditRoute);
