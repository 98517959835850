import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateForwarderService from '../services/updateForwarderService';
import forwarderService from '../services/forwarderService';
import ForwarderForm from './ForwarderForm';

const EditForwarder = ({
  open,
  dispatchUpdateForwarderPut,
  updateForwarderFetch,
  dispatchForwarderGet,
  forwarderFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateForwarderPut(
        id,
        body,
        () => history.push('/management/forwarders'),
        undefined,
        t
      ),
    [id, dispatchUpdateForwarderPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchForwarderGet(id, () => history.push('/management/forwarders'), t);
    }
  }, [id, open, t, dispatchForwarderGet]);

  const defaultData = useMemo(
    () =>
      !forwarderFetch.pending && {
        ...(forwarderFetch.value || {}),
      },
    [forwarderFetch]
  );

  if (!open) return null;

  return (
    <ForwarderForm
      header={forwarderFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateForwarderFetch.pending}
      returnRoute="/management/forwarders"
      cancelRoute="/management/forwarders"
      dataLoading={forwarderFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditForwarder.propTypes = {
  dispatchUpdateForwarderPut: PropTypes.func.isRequired,
  updateForwarderFetch: PropTypes.object.isRequired,
  dispatchForwarderGet: PropTypes.func.isRequired,
  forwarderFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditForwarder.defaultProps = { open: false };

export default compose(forwarderService, updateForwarderService)(EditForwarder);
