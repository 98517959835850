import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createEdlmService from '../services/createEdlmService';
import EdlmForm from './EdlmForm';

const EMPTY_EDLM = { name: '' };

const NewEdlm = ({ open, dispatchCreateEdlmPost, createEdlmFetch }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body => dispatchCreateEdlmPost(body, () => history.push('/management/edlms'), undefined, t),
    [dispatchCreateEdlmPost, t]
  );

  if (!open) return null;

  return (
    <EdlmForm
      header={t('createEdlm')}
      onSubmit={handleSubmit}
      submitProcessing={createEdlmFetch.pending}
      returnRoute="/management/edlms"
      cancelRoute="/management/edlms"
      data={EMPTY_EDLM}
    />
  );
};

NewEdlm.propTypes = {
  dispatchCreateEdlmPost: PropTypes.func.isRequired,
  createEdlmFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewEdlm.defaultProps = { open: false };

export default createEdlmService(NewEdlm);
