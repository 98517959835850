import reduxFetch from 'react-redux-fetch';
import { TEST_SHIPMENT_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const TestShipmentService = ComposedComponent =>
  reduxFetch([
    {
      resource: TEST_SHIPMENT_RESOURCE,
      method: 'get',
      request: (protocolId, id, rejectCallback, t) => ({
        url: apiRoutes.testShipments(protocolId, id),
        meta: {
          rejectCallback,
          toaster: {
            onErrorMessage: t('getTestShipmentFailed'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default TestShipmentService;
