import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export const useXAxisDomain = (data, milestones) =>
  useMemo(() => {
    if (!data?.length) return [];

    const d = sortBy([...(data || []), ...milestones], 'local.timestamp');
    return uniqBy(d, 'local.timestamp')?.map(ts => ts.local.timestamp) || [];
  }, [data, milestones]);
