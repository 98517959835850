import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { CREATE_PROTOCOL_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const CreateProtocolService = ComposedComponent =>
  reduxFetch([
    {
      resource: CREATE_PROTOCOL_RESOURCE,
      method: 'post',
      request: (body, callback, t) => ({
        url: apiRoutes.protocols(),
        body,
        meta: {
          callback,
          toaster: {
            onErrorMessage: createErrorMessage(t, 'createProtocolFailed'),
            onSuccessMessage: t('createProtocolSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default CreateProtocolService;
