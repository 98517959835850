import { useMemo } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import first from 'lodash/first';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex, Divider } from 'nallian-shared-ui/lib/components';
import get from 'lodash/get';

const findClosestNumber = (arr, target) => {
  const f = arr && first(arr);
  const l = arr && last(arr);

  if (target > l?.local?.timestamp) return null;
  if (target < f?.local?.timestamp) return null;

  return arr?.reduce((closest, current) => {
    return Math.abs(current?.local?.timestamp - target) <
      Math.abs(closest?.local?.timestamp - target)
      ? current
      : closest;
  });
};

const ToolTipStyle = styled.div`
  border: 1px solid #e3e3e3;
  background-color: white;
  border-radius: 0.5rem;

  p {
    background: #eceff1;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 1rem;
  }

  div {
    padding: 0 0.5rem;
  }

  .degrees {
    padding: 0.5rem 0.5rem;
  }

  -tw-bg-opacity: 1 !important;
  --tw-text-opacity: 1 !important;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
`;

const ChartToolTip = ({ active, payload, groupedData, serialNumbers }) => {
  const { t } = useTranslation();

  const data = get(payload, '[0].payload');
  const tooltipTimestamp = get(data, 'local.timestamp');
  // const celciusDegrees = get(payload, '[0].value');
  // const fahrenheitDegrees = Math.round(celciusDegrees * (9 / 5) + 32);

  const numbers = serialNumbers || Object.keys(groupedData);

  const numberTemperatures = useMemo(
    () =>
      numbers
        .map(serialNumber => {
          const values = groupedData[serialNumber];
          const closestDataPoint = findClosestNumber(values, tooltipTimestamp);

          if (!closestDataPoint) return null;

          return {
            serialNumber,
            temperature: closestDataPoint?.temperature,
          };
        })
        .filter(n => n),
    [numbers, groupedData, tooltipTimestamp]
  );

  if (!active || !tooltipTimestamp) return null;

  return (
    <ToolTipStyle className="custom-tooltip">
      <p>
        <b>{t('measuredTemperature')}</b>
      </p>
      <Flex direction="column">
        {numberTemperatures?.map(n => (
          <span key={n.serialNumber}>
            {n.serialNumber}: {n.temperature}°C
          </span>
        ))}
      </Flex>
      <Flex direction="column">
        <Divider />
        <span className="degrees">Local time:&nbsp;{data?.local?.display}</span>
        <span className="degrees">UTC time:&nbsp;{data?.utc?.display}</span>
        <span className="degrees">Origin time:&nbsp;{data?.origin?.display}</span>
      </Flex>
    </ToolTipStyle>
  );
};

ChartToolTip.propTypes = {
  serialNumbers: PropTypes.array,
  groupedData: PropTypes.object,
  active: PropTypes.bool,
  payload: PropTypes.object,
};

ChartToolTip.defaultProps = {
  serialNumbers: undefined,
  groupedData: {},
  active: false,
  payload: undefined,
};

export default ChartToolTip;
