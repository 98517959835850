import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import {
  fetchReducer,
  themeReducer,
  profileSettingsReducer,
  authorisationReducer,
} from 'nallian-shared-ui/lib/reducers';
import { toaster } from 'nallian-shared-ui/lib/components';
import { filtersReducer } from 'nallian-shared-ui/lib/hoc/redux-filters';
import { sortingReducer } from 'nallian-shared-ui/lib/hoc/redux-sorting';
// import { container } from 'react-redux-fetch';

export const loadReactReduxFetchExtensions = () => {
  // container.registerReducer('stsClientDetail', stsClientDetailReducer);
};

const rootReducer = combineReducers({
  form,
  auth: authorisationReducer,
  toastr: toaster.toasterReducer,
  repository: fetchReducer,
  theme: themeReducer,
  profileSettings: profileSettingsReducer,
  filters: filtersReducer,
  sorting: sortingReducer,
});

export default rootReducer;
