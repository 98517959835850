import { fork, all } from 'redux-saga/effects';
import {
  callbackSaga,
  redirectSaga,
  showToasterSaga,
  // authorisationSaga,
  uploadProfilePictureSaga,
  getThemeBlobSaga,
  languageSaga,
} from 'nallian-shared-ui/lib/sagas';

export default function* rootSaga() {
  yield all([
    fork(showToasterSaga),
    fork(callbackSaga),
    fork(redirectSaga),
    fork(getThemeBlobSaga),
    fork(uploadProfilePictureSaga),
    fork(languageSaga),
  ]);
}
