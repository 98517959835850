import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'nallian-shared-ui/lib/components';
import { InputFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { GridColumn } from '../../../app/styles';

const TopicField = ({ submitProcessing, field, index }) => {
  const { t } = useTranslation();

  // const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <GridColumn noPaddingBottom>
      <Field
        fluid
        label={index === 0 && t('topic')}
        name={`${field}.topic`}
        placeholder={t('enterATopic')}
        valueComponent={InputFieldValueComponent}
        disabled={submitProcessing}
      />
    </GridColumn>
  );
};

TopicField.propTypes = {
  index: PropTypes.number.isRequired,
  submitProcessing: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
};

export default TopicField;
