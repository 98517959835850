import React from 'react';
import { Route } from 'react-router-dom';
import RoutesOverview from '../components/RoutesOverview';

const RoutesScene = () => (
  <>
    <Route exact path="/routes/:action?/:id?" menuName="routes_plural" component={RoutesOverview} />
  </>
);

export default RoutesScene;
