import PropTypes from 'prop-types';
import { Flex, CountryName } from 'nallian-shared-ui/lib/components';

const Location = ({ item, name, fallback }) => {
  const value = item[name] || item[fallback];

  return (
    <Flex fluid direction="column">
      <Flex>
        {value?.name || value?.port},&nbsp;{value?.streetAndNumber}
      </Flex>
      <Flex>
        {value?.zipCode}&nbsp;{value?.city},&nbsp;
        <CountryName code={value?.country} />
      </Flex>
    </Flex>
  );
};

Location.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
};

export default Location;
