export const ROUTES_RESOURCE = 'routes';
export const ROUTE_RESOURCE = 'route';
export const CREATE_ROUTE_RESOURCE = 'createRoute';
export const UPDATE_ROUTE_RESOURCE = 'updateRoute';
export const REMOVE_ROUTE_RESOURCE = 'removeRoute';

export const INFINITE_SCROLL = { loader: 'blocked' };
export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
};

export const FORM_NAME = 'routeForm';
