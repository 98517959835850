import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Button, Flex } from 'nallian-shared-ui/lib/components';
import { SelectFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { GridColumn } from '../../../app/styles';
import { MONTH_OPTIONS, PERIOD_OPTIONS } from '../../constants';

const TestShipmentField = ({ index, submitProcessing, field, fields }) => {
  const { t } = useTranslation();

  const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <>
      <GridColumn correctBottom noPaddingBottom>
        <Field
          fluid
          search
          clearable
          label={index <= 0 && t('month')}
          placeholder={t('selectAMonth')}
          name={`${field}.month`}
          valueComponent={SelectFieldValueComponent}
          options={MONTH_OPTIONS}
          disabled={submitProcessing}
        />
      </GridColumn>
      <GridColumn correctBottom noPaddingBottom>
        <Flex fluid>
          <Field
            fluid
            search
            clearable
            label={index <= 0 && t('period')}
            placeholder={t('selectAPeriod')}
            name={`${field}.period`}
            valueComponent={SelectFieldValueComponent}
            options={PERIOD_OPTIONS}
            disabled={submitProcessing}
          />
          <Button
            linkbutton
            iconbutton
            icon="close"
            type="button"
            id="removeIdCode"
            margin={index === 0 ? '-0.4rem 0 0 0.3rem' : '-1.9rem 0 0 0.3rem'}
            tabindex="-1"
            i={index}
            onClick={handleRemoveFieldClick}
          />
        </Flex>
      </GridColumn>
      <GridColumn correctBottom noPaddingBottom />
    </>
  );
};

TestShipmentField.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  field: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
};

export default TestShipmentField;
