import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const PackagingsScene = () => (
  <>
    <Route
      exact
      path="/management/packagings/:id?"
      menuName="packagings_plural"
      component={Overview}
    />
  </>
);

export default PackagingsScene;
