import Scene from '../scenes/CustomersScene';

const routes = [
  {
    path: '/management/customers',
    menuName: 'customers_plural',
    component: Scene,
  },
];

export default routes;
