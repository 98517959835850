import { useTranslation } from 'react-i18next';
import { PageTitle, PageContent } from 'nallian-shared-ui/lib/components';
import { Table, Params, Menu } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, TRANSPORT_MODES_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const TransportModesOverview = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('transportModes_plural')}</PageTitle>
      <PageContent>
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={TRANSPORT_MODES_RESOURCE}
          url={apiRoutes.transportModes()}
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Params.String name="name" width="11rem" noFilter grow />
          <Menu width="3.5rem" align="center" alignItems="center" />
        </Table>
      </PageContent>
    </>
  );
};

TransportModesOverview.propTypes = {};

export default TransportModesOverview;
