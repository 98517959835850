import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';

import createCustomerService from '../services/createCustomerService';
import CustomerForm from './CustomerForm';

const EMPTY_CUSTOMER = { name: '' };

const NewCustomer = ({ open, dispatchCreateCustomerPost, createCustomerFetch }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body =>
      dispatchCreateCustomerPost(body, () => history.push('/management/customers'), undefined, t),
    [dispatchCreateCustomerPost, t]
  );

  if (!open) return null;

  return (
    <CustomerForm
      header={t('createCustomer')}
      onSubmit={handleSubmit}
      submitProcessing={createCustomerFetch.pending}
      returnRoute="/management/customers"
      cancelRoute="/management/customers"
      data={EMPTY_CUSTOMER}
    />
  );
};

NewCustomer.propTypes = {
  dispatchCreateCustomerPost: PropTypes.func.isRequired,
  createCustomerFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewCustomer.defaultProps = { open: false };

export default createCustomerService(NewCustomer);
