import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateCustomerService from '../services/updateCustomerService';
import customerService from '../services/customerService';
import CustomerForm from './CustomerForm';

const EditCustomer = ({
  open,
  dispatchUpdateCustomerPut,
  updateCustomerFetch,
  dispatchCustomerGet,
  customerFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateCustomerPut(
        id,
        body,
        () => history.push('/management/customers'),
        undefined,
        t
      ),
    [id, dispatchUpdateCustomerPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchCustomerGet(id, () => history.push('/management/customers'), t);
    }
  }, [id, open, t, dispatchCustomerGet]);

  const defaultData = useMemo(
    () =>
      !customerFetch.pending && {
        ...(customerFetch.value || {}),
      },
    [customerFetch]
  );

  if (!open) return null;

  return (
    <CustomerForm
      header={customerFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateCustomerFetch.pending}
      returnRoute="/management/customers"
      cancelRoute="/management/customers"
      dataLoading={customerFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditCustomer.propTypes = {
  dispatchUpdateCustomerPut: PropTypes.func.isRequired,
  updateCustomerFetch: PropTypes.object.isRequired,
  dispatchCustomerGet: PropTypes.func.isRequired,
  customerFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditCustomer.defaultProps = { open: false };

export default compose(customerService, updateCustomerService)(EditCustomer);
