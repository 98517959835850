import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { F, Flex, Button, Divider } from 'nallian-shared-ui/lib/components';
import MilestoneField from './MilestoneField';
import { FieldFlex } from '../../../../app/styles';

const MilestonesFields = ({ submitProcessing, dataLoading, fields }) => {
  const { t } = useTranslation();

  const handleAddFieldClick = useCallback(() => fields.push({ isNew: true }), [fields]);

  useEffect(() => {
    if (fields?.length <= 0) fields.push({ name: '' });
  }, [fields]);

  return (
    <F.Field fluid error={false}>
      <Flex fluid direction="column">
        <FieldFlex fluid direction="column">
          {!fields?.length && <Flex>{t('noMilestonesDefined')}</Flex>}
          {fields.map((f, index) => (
            <MilestoneField
              field={f}
              key={f}
              index={index}
              submitProcessing={submitProcessing}
              dataLoading={dataLoading}
              fields={fields}
            />
          ))}
        </FieldFlex>
        <Divider />
        <Flex fluid justifyContent="flex-end" margin="1rem 0 1rem 0">
          <Button
            linkbutton
            icon="add"
            type="button"
            tabindex="-1"
            content={t('addMilestone')}
            onClick={handleAddFieldClick}
          />
        </Flex>
      </Flex>
    </F.Field>
  );
};

MilestonesFields.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
};

MilestonesFields.defaultProps = {};

export default MilestonesFields;
