import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createProductPackagingService from '../services/createProductPackagingService';
import ProductPackagingForm from './ProductPackagingForm';

const EMPTY_PRODUCT_PACKAGING = { name: '' };

const NewProductPackaging = ({
  open,
  dispatchCreateProductPackagingPost,
  createProductPackagingFetch,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body =>
      dispatchCreateProductPackagingPost(
        body,
        () => history.push('/management/product-packagings'),
        undefined,
        t
      ),
    [dispatchCreateProductPackagingPost, t]
  );

  if (!open) return null;

  return (
    <ProductPackagingForm
      header={t('createProductPackaging')}
      onSubmit={handleSubmit}
      submitProcessing={createProductPackagingFetch.pending}
      returnRoute="/management/product-packagings"
      cancelRoute="/management/product-packagings"
      data={EMPTY_PRODUCT_PACKAGING}
    />
  );
};

NewProductPackaging.propTypes = {
  dispatchCreateProductPackagingPost: PropTypes.func.isRequired,
  createProductPackagingFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewProductPackaging.defaultProps = { open: false };

export default createProductPackagingService(NewProductPackaging);
