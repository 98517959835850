import PropTypes from 'prop-types';
import { Flex, Header, Divider } from 'nallian-shared-ui/lib/components';
import styled from 'styled-components';

const FullWithHeader = styled(Header)`
  width: 100%;
`;

const FormHeader = ({ content }) => (
  <Flex fluid direction="column">
    <Flex fluid margin="0.5rem 0 -0.5rem">
      <FullWithHeader as="h3">{content}</FullWithHeader>
    </Flex>
    <Divider />
  </Flex>
);

FormHeader.propTypes = { content: PropTypes.string.isRequired };

export default FormHeader;
