import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const ProductTypesScene = () => (
  <>
    <Route
      exact
      path="/management/product-types/:id?"
      menuName="productTypes_plural"
      component={Overview}
    />
  </>
);

export default ProductTypesScene;
