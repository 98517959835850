import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { UPDATE_ROUTE_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const UpdateRouteService = ComposedComponent =>
  reduxFetch([
    {
      resource: UPDATE_ROUTE_RESOURCE,
      method: 'put',
      request: (id, body, callback, rejectCallback, t) => ({
        url: apiRoutes.routes(id),
        body,
        meta: {
          callback,
          rejectCallback,
          toaster: {
            onErrorMessage: createErrorMessage(t, 'updateRouteFailed'),
            onSuccessMessage: t('updateRouteSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default UpdateRouteService;
