import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Field, Flex } from 'nallian-shared-ui/lib/components';
import { useTranslation } from 'react-i18next';
import { FieldArray, getFormSyncErrors } from 'redux-form';
import { useSelector } from 'react-redux';
import set from 'lodash/set';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import {
  InputFieldValueComponent,
  LookupFieldValueComponent,
  SelectFieldValueComponent,
  TextAreaFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { history } from 'nallian-shared-ui/lib/config/history';
import { FormModal } from 'nallian-shared-ui/lib/modules';
import { FORM_NAME } from '../constants';
import FormHeader from '../../app/components/FormHeader';
import { Step, Hidden, StepNumber } from '../../app/styles';
import apiRoutes from '../../../app/api/routes';
import TestShipmentFields from './form/TestShipmentFields';
import EdlmFields from './form/EdlmFields';
import CriteriaFields from './form/CriteriaFields';

const FIRST_TAB_FIELDS = [
  'routeId',
  'customerId',
  'productId',
  'packagingId',
  'contactPerson.name',
  'contactPerson.emailAddress',
  'contactPerson.mobileNumber',
  'contactPerson.company',
];

const SECOND_TAB_FIELDS = ['testShipments'];
const THIRD_TAB_FIELDS = ['edlms'];
const FOURTH_TAB_FIELDS = ['minTransportTemperature', 'maxTransportTemperature'];

const REQUIRED_FIELDS = [...FIRST_TAB_FIELDS, ...FOURTH_TAB_FIELDS];

const TEST_SHIPMENTS_REQUIRED_FIELDS = ['month', 'period'];
const EDLM_SHIPMENTS_REQUIRED_FIELDS = [
  'id',
  'timeZone',
  'measuringInterval',
  'startDelay',
  'type',
];

const STEPS = {
  ROUTE: 'ROUTE',
  TEST_SHIPMENTS: 'TEST_SHIPMENTS',
  EDLMS: 'EDLMS',
  CRITERIA: 'CRITERIA',
};

const normalizeNumber = value => isNumber(Number(value)) && Math.abs(Number(value));

const validate = (values, { t }) => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);
  (values.testShipments || []).forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      TEST_SHIPMENTS_REQUIRED_FIELDS.map(f => ({ fieldKey: `testShipments[${i}].${f}`, key: f })),
      values,
      errors
    );
    if (values.testShipments.find((ts, tsi) => tsi !== i && ts.month === _.month)) {
      set(errors, `testShipments[${i}].month`, t('testShipmentAlreadyExists'));
    }
  });
  (values.edlms || []).forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      EDLM_SHIPMENTS_REQUIRED_FIELDS.map(f => ({ fieldKey: `edlms[${i}].${f}`, key: f })),
      values,
      errors
    );
  });

  return errors;
};

const ProtocolForm = ({
  submitProcessing,
  dataLoading,
  data,
  returnRoute,
  onSubmit,
  header,
  routesFetch,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEPS.ROUTE);

  const handleSetStep = useCallback((_, item) => setStep(item.step), [setStep]);

  const handlePreviousStep = useCallback(() => {
    const keys = Object.keys(STEPS);
    const nextIndex = keys.indexOf(step) - 1;
    setStep(keys[nextIndex]);
  }, [step, setStep]);

  const handleNextStep = useCallback(() => {
    const keys = Object.keys(STEPS);
    const nextIndex = keys.indexOf(step) + 1;
    setStep(keys[nextIndex]);
  }, [step, setStep]);

  const handleGoBack = useCallback(() => history.push(returnRoute), [returnRoute]);

  const routesOptions = useMemo(
    () => (routesFetch.value || []).map(r => ({ key: r._id, value: r._id, text: r.name })),
    [routesFetch.value]
  );

  const isLastStep = step === STEPS.CRITERIA;
  const isFirstStep = step === STEPS.ROUTE;

  const errors = useSelector(state => getFormSyncErrors(FORM_NAME)(state));
  const submitFailed = useSelector(state => get(state, `form.${FORM_NAME}.submitFailed`));

  const firstTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, FIRST_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  const secondTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, SECOND_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  const thirdTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, THIRD_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  const fourthTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, FOURTH_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      customOnSubmit={!isLastStep ? handleNextStep : undefined}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      onDimmerClick={handleGoBack}
      onCancel={!isFirstStep ? handlePreviousStep : handleGoBack}
      cancelButtonContent={!isFirstStep ? t('back') : t('cancel')}
      saveButtonIcon={isLastStep ? 'save' : 'arrow right'}
      saveButtonContent={isLastStep ? t('save') : t('next')}
      saveButtonIconPosition={isLastStep ? 'left' : 'right'}
    >
      <Flex margin="-0.5rem 0" />
      <Step.Group fluid>
        <Step
          fluid
          link
          width="25%"
          active={step === STEPS.ROUTE}
          step={STEPS.ROUTE}
          onClick={handleSetStep}
          error={firstTabsErrors}
        >
          <StepNumber>1</StepNumber>
          <Step.Content>
            <Step.Title>{t('route')}</Step.Title>
            <Step.Description>{t('configureRoute')}</Step.Description>
          </Step.Content>
        </Step>
        <Step
          fluid
          link
          width="25%"
          active={step === STEPS.TEST_SHIPMENTS}
          step={STEPS.TEST_SHIPMENTS}
          onClick={handleSetStep}
          error={secondTabsErrors}
        >
          <StepNumber>2</StepNumber>
          <Step.Content>
            <Step.Title>{t('testShipments')}</Step.Title>
            <Step.Description>{t('configureTestShipments')}</Step.Description>
          </Step.Content>
        </Step>
        <Step
          fluid
          link
          width="25%"
          active={step === STEPS.EDLMS}
          step={STEPS.EDLMS}
          onClick={handleSetStep}
          error={thirdTabsErrors}
        >
          <StepNumber>3</StepNumber>
          <Step.Content>
            <Step.Title>{t('edlms')}</Step.Title>
            <Step.Description>{t('configureEdlms')}</Step.Description>
          </Step.Content>
        </Step>
        <Step
          fluid
          link
          width="25%"
          active={step === STEPS.CRITERIA}
          step={STEPS.CRITERIA}
          error={fourthTabsErrors}
          onClick={handleSetStep}
        >
          <StepNumber>4</StepNumber>
          <Step.Content>
            <Step.Title>{t('Criterias')}</Step.Title>
            <Step.Description>{t('configureCriterias')}</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      <Hidden hidden={step !== STEPS.ROUTE}>
        <FormHeader content="Route" />
        <Grid>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('route')}
              name="routeId"
              options={routesOptions}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('customer')}
              name="customerId"
              valueProp="_id"
              url={apiRoutes.customers()}
              valueComponent={LookupFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('product')}
              name="productId"
              valueProp="_id"
              url={apiRoutes.products()}
              valueComponent={LookupFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('packaging')}
              name="packagingId"
              valueProp="_id"
              url={apiRoutes.packagings()}
              valueComponent={LookupFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Introduction" />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              fluid
              label={t('introduction')}
              name="introduction"
              valueComponent={TextAreaFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Contact person" />
        <Grid columns="2">
          <Grid.Column>
            <Field
              fluid
              label={t('fullName')}
              name="contactPerson.name"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              fluid
              label={t('emailAddress')}
              name="contactPerson.emailAddress"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              fluid
              label={t('phoneNumber')}
              name="contactPerson.mobileNumber"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              fluid
              label={t('company')}
              name="contactPerson.company"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
      </Hidden>
      <Hidden hidden={step !== STEPS.TEST_SHIPMENTS}>
        <FormHeader content="Test shipments" />
        <FieldArray name="testShipments" component={TestShipmentFields} />
      </Hidden>
      <Hidden hidden={step !== STEPS.EDLMS}>
        <FormHeader content="Edlms" />
        <FieldArray name="edlms" component={EdlmFields} />
      </Hidden>
      <Hidden hidden={step !== STEPS.CRITERIA}>
        <FormHeader content="Transport temperature" />
        <Grid columns="2">
          <Grid.Column>
            <Field
              fluid
              type="number"
              normalize={normalizeNumber}
              label={t('minTransportTemperature')}
              name="minTransportTemperature"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column>
            <Field
              fluid
              type="number"
              normalize={normalizeNumber}
              label={t('maxTransportTemperature')}
              name="maxTransportTemperature"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Criteria" />
        <FieldArray name="acceptanceCriterias" component={CriteriaFields} />
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Conclusion" />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              fluid
              label={t('conclusion')}
              name="conclusion"
              valueComponent={TextAreaFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
      </Hidden>
    </FormModal>
  );
};

ProtocolForm.propTypes = {
  routesFetch: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  data: PropTypes.object,
};

ProtocolForm.defaultProps = {
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default lookupService({
  resource: 'routes',
  url: apiRoutes.routes(),
})(ProtocolForm);
