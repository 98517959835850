import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Field, Flex } from 'nallian-shared-ui/lib/components';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'redux-form';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import {
  InputFieldValueComponent,
  FileFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { FormModal } from 'nallian-shared-ui/lib/modules';
import EdlmFields from './form/EdlmFields';
import DownloadButton from '../../app/components/DownloadButton';
import FormHeader from '../../app/components/FormHeader';

const REQUIRED_FIELDS = ['reference'];
const FORM_NAME = 'testShipment';

const validate = values => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);

  return errors;
};

const TestShipmentForm = ({
  submitProcessing,
  dataLoading,
  data,
  returnRoute,
  onSubmit,
  header,
  protocol,
}) => {
  const { t } = useTranslation();

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      cancelRoute={returnRoute}
      cancelButtonContent={t('cancel')}
      saveButtonIcon="save"
      saveButtonContent={t('save')}
    >
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Field
            fluid
            label={t('reference')}
            name="reference"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <FieldArray name="edlms" component={EdlmFields} protocol={protocol} />
        </Grid.Column>
        <FormHeader content="Documents" />
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Field
            fluid
            label={
              <Flex justifyContent="space-between" alignItems="flex-end">
                {t('loadingDocument')}
                {data?.loadingDocument && (
                  <DownloadButton
                    btnprimary
                    size="small"
                    icon="download"
                    content={t('download')}
                    file={data.loadingDocument}
                  />
                )}
              </Flex>
            }
            name="loadingDocument"
            valueComponent={FileFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Field
            fluid
            label={
              <Flex justifyContent="space-between" alignItems="flex-end">
                {t('edlmDataDocument')}
                {data?.edlmDataDocument && (
                  <DownloadButton
                    btnprimary
                    size="small"
                    icon="download"
                    content={t('download')}
                    file={data.edlmDataDocument}
                  />
                )}
              </Flex>
            }
            name="edlmDataDocument"
            valueComponent={FileFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
      </Grid>
    </FormModal>
  );
};

TestShipmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  data: PropTypes.object,
  protocol: PropTypes.object,
};

TestShipmentForm.defaultProps = {
  protocol: undefined,
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default TestShipmentForm;
