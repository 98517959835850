import Scene from '../scenes/ProductTypesScene';

const routes = [
  {
    path: '/management/product-types',
    menuName: 'productTypes_plural',
    component: Scene,
  },
];

export default routes;
