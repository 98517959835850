import Scene from '../scenes/TransportModesScene';

const routes = [
  {
    path: '/management/transport-modes',
    menuName: 'transportModes_plural',
    component: Scene,
  },
];

export default routes;
