export const mapFormToProtocol = values => ({
  ...values,
  acceptanceCriterias: (values.acceptanceCriterias || []).filter(c => c.criteria),
  edlms: (values.edlms || [])
    .map(edlm =>
      !edlm.id
        ? undefined
        : {
            ...edlm,
            measuringInterval: Number(edlm.measuringInterval),
            startDelay: Number(edlm.startDelay),
          }
    )
    .filter(e => e),
});
