import axios from 'axios';
import apiRoutes from '../../../app/api/routes';

export const uploadFile = async file => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await axios.post(apiRoutes.blobs(), formData, {
    headers: { 'content-type': undefined },
  });

  if (!data?.etag) return false;

  return true;
};
