import reduxFetch from 'react-redux-fetch';
import { ROUTES_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const RoutesService = ComposedComponent =>
  reduxFetch([
    {
      resource: ROUTES_RESOURCE,
      method: 'get',
      request: () => ({
        url: apiRoutes.routes(),
      }),
    },
  ])(ComposedComponent);

export default RoutesService;
