import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageTitle, PageContent, Flex, Link, Button } from 'nallian-shared-ui/lib/components';
import { Table, Params, Menu, Actions } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, PRODUCTS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';
import NewProduct from './NewProduct';
import EditProduct from './EditProduct';

const ProductsOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <PageTitle>{t('products_plural')}</PageTitle>
      <PageContent>
        <NewProduct open={id && id === 'add'} />
        <EditProduct open={id && id !== 'add'} />
        <Flex>
          <Link to="/management/products/add">
            <Button btnprimary icon="add" content={t('createProduct')} />
          </Link>
        </Flex>
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={PRODUCTS_RESOURCE}
          url={apiRoutes.products()}
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url="/management/products/:_id"
          />
          <Params.String name="name" width="11rem" noFilter grow />
          <Params.String name="referenceNumber" width="11rem" noFilter grow />
          <Menu width="3.5rem" align="center" alignItems="center" />
        </Table>
      </PageContent>
    </>
  );
};

ProductsOverview.propTypes = {};

export default ProductsOverview;
