import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const ProductPhasesScene = () => (
  <>
    <Route
      exact
      path="/management/product-phases/:id?"
      menuName="productPhases_plural"
      component={Overview}
    />
  </>
);

export default ProductPhasesScene;
