import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { UPDATE_TEST_SHIPMENT_RESOURCE, TEST_SHIPMENTS_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const UpdateTestShipmentService = ComposedComponent =>
  reduxFetch([
    {
      resource: UPDATE_TEST_SHIPMENT_RESOURCE,
      method: 'put',
      request: (protocolId, id, body, callback, rejectCallback, t) => ({
        url: apiRoutes.testShipments(protocolId, id),
        body,
        meta: {
          updateOtherList: {
            resource: TEST_SHIPMENTS_RESOURCE,
            idProp: '_id',
            id,
            values: body,
          },
          callback,
          rejectCallback,
          toaster: {
            onErrorMessage: createErrorMessage(t, 'updateTestShipmentFailed'),
            onSuccessMessage: t('updateTestShipmentSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default UpdateTestShipmentService;
