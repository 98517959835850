import Scene from '../scenes/EdlmsScene';

const routes = [
  {
    path: '/management/edlms',
    menuName: 'edlms_plural',
    component: Scene,
  },
];

export default routes;
