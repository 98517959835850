import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateProductService from '../services/updateProductService';
import productService from '../services/productService';
import ProductForm from './ProductForm';
import { mapFormToProduct } from '../helpers/mapFormToProduct';

const EditProduct = ({
  open,
  dispatchUpdateProductPut,
  updateProductFetch,
  dispatchProductGet,
  productFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateProductPut(
        id,
        mapFormToProduct(body),
        () => history.push('/management/products'),
        undefined,
        t
      ),
    [id, dispatchUpdateProductPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchProductGet(id, () => history.push('/management/products'), t);
    }
  }, [id, open, t, dispatchProductGet]);

  const defaultData = useMemo(
    () =>
      !productFetch.pending && {
        ...(productFetch.value || {}),
      },
    [productFetch]
  );

  if (!open) return null;

  return (
    <ProductForm
      header={productFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateProductFetch.pending}
      returnRoute="/management/products"
      cancelRoute="/management/products"
      dataLoading={productFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditProduct.propTypes = {
  dispatchUpdateProductPut: PropTypes.func.isRequired,
  updateProductFetch: PropTypes.object.isRequired,
  dispatchProductGet: PropTypes.func.isRequired,
  productFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditProduct.defaultProps = { open: false };

export default compose(productService, updateProductService)(EditProduct);
