import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import { Field, Grid, Segment, Button } from 'nallian-shared-ui/lib/components';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import {
  InputFieldValueComponent,
  SelectFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { GridColumn } from '../../../app/styles';
import apiRoutes from '../../../../app/api/routes';

const normalizeNumber = value => isNumber(Number(value)) && Math.abs(Number(value));

const EdlmField = ({
  timezonesFetch,
  edlmsFetch,
  submitProcessing,
  dataLoading,
  field,
  fields,
  index,
}) => {
  const { t } = useTranslation();

  const timezoneOptions = useMemo(
    () => (timezonesFetch.value || []).map(v => ({ key: v, value: v, text: v })),
    [timezonesFetch.value]
  );

  const edlmOptions = useMemo(
    () => (edlmsFetch.value || []).map(e => ({ key: e._id, value: e._id, text: e.name })),
    [edlmsFetch.value]
  );

  const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <Segment secondary>
      <Grid>
        <Grid.Column width={8}>
          <Field
            fluid
            search
            clearable
            label={t('edlm')}
            name={`${field}.id`}
            options={edlmOptions}
            valueComponent={SelectFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Field
            fluid
            search
            label={t('timeZone')}
            name={`${field}.timeZone`}
            valueComponent={SelectFieldValueComponent}
            options={timezoneOptions}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <GridColumn width={8}>
          <Field
            fluid
            type="number"
            label={t('measuringInterval')}
            name={`${field}.measuringInterval`}
            normalize={normalizeNumber}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </GridColumn>
        <GridColumn width={8}>
          <Field
            fluid
            type="number"
            label={t('startDelay')}
            name={`${field}.startDelay`}
            normalize={normalizeNumber}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </GridColumn>
        <Grid.Column width={16} textAlign="right">
          <Button
            linkbutton
            content={t('removeEdlm')}
            icon="close"
            type="button"
            id="removeIdCode"
            tabindex="-1"
            i={index}
            onClick={handleRemoveFieldClick}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

EdlmField.propTypes = {
  timezonesFetch: PropTypes.object.isRequired,
  edlmsFetch: PropTypes.object.isRequired,
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default compose(
  lookupService({
    resource: 'timezones',
    url: apiRoutes.timezones(),
  }),
  lookupService({
    resource: 'edlms',
    url: apiRoutes.edlms(),
  })
)(EdlmField);
