import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createProtocolService from '../services/CreateProtocolService';
import protocolsService from '../services/ProtocolsService';
import ProtocolForm from './ProtocolForm';
import { mapFormToProtocol } from '../helpers/mapFormToProtocol';

const EMPTY_PROTOCOL = { contactPerson: { name: '' } };

const NewProtocol = ({
  open,
  dispatchCreateProtocolPost,
  createProtocolFetch,
  dispatchProtocolsGet,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body =>
      dispatchCreateProtocolPost(
        mapFormToProtocol(body),
        () => {
          dispatchProtocolsGet();
          history.push(`/protocols`);
        },
        t
      ),
    [dispatchCreateProtocolPost, dispatchProtocolsGet, t]
  );

  if (!open) return null;

  return (
    <ProtocolForm
      header={t('createProtocol')}
      onSubmit={handleSubmit}
      submitProcessing={createProtocolFetch.pending}
      returnRoute="/protocols"
      cancelRoute="/protocols"
      data={EMPTY_PROTOCOL}
    />
  );
};

NewProtocol.propTypes = {
  dispatchProtocolsGet: PropTypes.func.isRequired,
  dispatchCreateProtocolPost: PropTypes.func.isRequired,
  createProtocolFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewProtocol.defaultProps = { open: false };

export default compose(protocolsService, createProtocolService)(NewProtocol);
