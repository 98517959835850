import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { F, Flex, Button, Grid } from 'nallian-shared-ui/lib/components';
import CriteriaField from './CriteriaField';
import { FieldFlex } from '../../../app/styles';

const CriteriaShipmentFields = ({ submitProcessing, dataLoading, fields }) => {
  const { t } = useTranslation();

  const handleAddFieldClick = useCallback(() => fields.push({ isNew: true }), [fields]);

  return (
    <F.Field error={false}>
      <Flex fluid direction="column" margin="1rem 0 0">
        <FieldFlex fluid direction="column">
          {!fields?.length && <Flex>{t('noCriteriasDefined')}</Flex>}
          <Grid stretched columns="1">
            {fields.map((f, index) => (
              <CriteriaField
                field={f}
                key={f}
                index={index}
                submitProcessing={submitProcessing}
                dataLoading={dataLoading}
              />
            ))}
          </Grid>
        </FieldFlex>
        <Flex fluid justifyContent="flex-end" margin="1rem 0 1rem 0">
          <Button
            linkbutton
            icon="add"
            type="button"
            tabindex="-1"
            content={t('addCriteria')}
            onClick={handleAddFieldClick}
          />
        </Flex>
      </Flex>
    </F.Field>
  );
};

CriteriaShipmentFields.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
};

export default CriteriaShipmentFields;
