import reduxFetch from 'react-redux-fetch';
import { PRODUCT_PACKAGING_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const ProductPackagingService = ComposedComponent =>
  reduxFetch([
    {
      resource: PRODUCT_PACKAGING_RESOURCE,
      method: 'get',
      request: (id, rejectCallback, t) => ({
        url: apiRoutes.productPackagings(id),
        meta: {
          rejectCallback,
          toaster: {
            onErrorMessage: t('getProductPackagingFailed'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default ProductPackagingService;
