import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageTitle, PageContent, Flex, Link, Button } from 'nallian-shared-ui/lib/components';
import { Table, Params, Menu, Actions } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, FORWARDERS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';
import NewForwarder from './NewForwarder';
import EditForwarder from './EditForwarder';

const ForwardersOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <PageTitle>{t('forwarders_plural')}</PageTitle>
      <PageContent>
        <NewForwarder open={id && id === 'add'} />
        <EditForwarder open={id && id !== 'add'} />
        <Flex>
          <Link to="/management/forwarders/add">
            <Button btnprimary icon="add" content={t('createForwarder')} />
          </Link>
        </Flex>
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={FORWARDERS_RESOURCE}
          url={apiRoutes.forwarders()}
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url="/management/forwarders/:_id"
          />
          <Params.String name="name" width="11rem" noFilter grow />
          <Menu width="3.5rem" align="center" alignItems="center" />
        </Table>
      </PageContent>
    </>
  );
};

ForwardersOverview.propTypes = {};

export default ForwardersOverview;
