import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Papa from 'papaparse';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import format from 'date-fns/format';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import uniqBy from 'lodash/uniqBy';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from 'recharts';
import {
  PageTitle,
  PageContent,
  Flex,
  Dropdown,
  Loader,
  Divider,
  Message,
  Link,
  Popup,
} from 'nallian-shared-ui/lib/components';
import protocolService from '../../protocols/services/ProtocolService';
import testShipmentService from '../../testShipments/services/testShipmentService';
import { downloadFile } from '../../app/helpers/downloadFile';
import { useYAxisDomain } from '../hooks/useYAxisDomain';
import { useXAxisDomain } from '../hooks/useXAxisDomain';
import { getClosestDates } from '../helpers/getClosestDates';
import ChartToolTip from './ChartToolTip';
import { TEMPERATURE_TYPE_OPTIONS, TEMPERATURE_TYPES } from '../constants';

const ChartStyleWrapper = styled.div`
  width: 100%;
  height: 650px;
`;

const COLORS = ['#db2828', '#fbbd08', '#21ba45', '#a5673f', '#a333c8', '#e03997', '#1b1c1d'];

const MilestoneText = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CustomLegend = ({ payload }) => {
  return (
    <Flex fluid direction="column" alignItems="center">
      <ul className="recharts-default-legend">
        {(payload?.milestones || []).map(ms => (
          <li
            key={ms.name}
            className="recharts-legend-item legend-item-0"
            style={{ display: 'inline-block', marginRight: '10px' }}
          >
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}
            >
              <title />
              <desc />
              <path
                stroke="none"
                fill="#2185d0"
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </svg>
            <span className="recharts-legend-item-text" style={{ color: ms.color }}>
              {ms.value}
            </span>
          </li>
        ))}
      </ul>
      <ul className="recharts-default-legend">
        {(payload?.serialNumbers || []).map(sn => (
          <li
            key={sn.name}
            className="recharts-legend-item legend-item-0"
            style={{ display: 'inline-block', marginRight: '10px' }}
          >
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}
            >
              <title />
              <desc />
              <line
                strokeWidth="4"
                fill="none"
                stroke={sn.color}
                x1="0"
                y1="16"
                x2="32"
                y2="16"
                className="recharts-legend-icon"
              />
            </svg>
            <span className="recharts-legend-item-text" style={{ color: sn.color }}>
              {sn.value}
            </span>
          </li>
        ))}
      </ul>
    </Flex>
  );
};

CustomLegend.propTypes = { payload: PropTypes.object };
CustomLegend.defaultProps = { payload: {} };

const ReferenceLineLabel = ({ label, viewBox, left }) => {
  const { y, width } = viewBox || {};
  const centerX = left ? 30 : width;
  const centerY = y - 10; // Adjust the vertical position as needed
  return (
    <text x={centerX} y={centerY} fontSize="12" textAnchor={left ? 'start' : 'end'} fill="red">
      {label}
    </text>
  );
};

ReferenceLineLabel.propTypes = {
  viewBox: PropTypes.object.isRequired,
  label: PropTypes.string,
  left: PropTypes.bool,
};

ReferenceLineLabel.defaultProps = { label: undefined, left: false };

const ReferenceAreaLabel = ({ label, index, viewBox }) => (
  <g>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <foreignObject {...viewBox}>
      <Popup
        position="top center"
        on="hover"
        trigger={<MilestoneText>{index + 1}</MilestoneText>}
        inverted
        flowing
        hoverable
      >
        {label}
      </Popup>
    </foreignObject>
  </g>
);

ReferenceAreaLabel.propTypes = {
  index: PropTypes.number.isRequired,
  viewBox: PropTypes.object.isRequired,
  label: PropTypes.string,
};
ReferenceAreaLabel.defaultProps = { label: undefined };

const createMilestones = (milestones, xAxisDomain, yAxisDomain) => {
  if (!xAxisDomain?.length || !milestones?.length || !yAxisDomain?.length) return [];

  return milestones
    .map((ms, i) => {
      const nextMilestone = milestones[i + 1];
      const lastTimeStamp = last(xAxisDomain);
      return {
        label: ms.name,
        // use 0 for earliest, 9999999999 for latest date possible
        start: i === 0 ? 0 : ms.local.timestamp,
        end: !nextMilestone ? lastTimeStamp : nextMilestone.local.timestamp,
      };
    })
    .map((ms, i) => {
      const y1 = last(yAxisDomain);
      const [closestStartDate, closestEndDate] = getClosestDates(xAxisDomain, ms.start, ms.end);

      return (
        <ReferenceArea
          isFront
          fill="#2185d0"
          fillOpacity={1}
          y1={y1}
          y2={y1 - 5}
          key={`ms_${ms.start}_${ms.end}`}
          x1={closestStartDate}
          x2={closestEndDate}
          stroke="rgba(255, 255, 255)"
          strokeWidth={3}
          label={<ReferenceAreaLabel label={ms.label} index={i} />}
        />
      );
    });
};

const formatTimestamp = ts => {
  const date = new Date(ts * 1000);
  return format(date, 'dd/MM/yy HH:mm');
};

const formatTemperature = temperature => Math.round(temperature);

const Charts = ({
  dispatchProtocolGet,
  protocolFetch,
  dispatchTestShipmentGet,
  testShipmentFetch,
}) => {
  const { t } = useTranslation();
  const [edlmData, setEdlmData] = useState();
  const [temperatureTypes, setTemperatureTypes] = useState();
  const [serialNumbers, setSerialNumbers] = useState();

  const { id: protocolId, subResourceId: testShipmentId } = useParams();
  const edlmDataDocument = testShipmentFetch.value?.edlmDataDocument;

  useEffect(
    () => dispatchProtocolGet(protocolId, undefined, t),
    [dispatchProtocolGet, protocolId, t]
  );

  useEffect(
    () => dispatchTestShipmentGet(protocolId, testShipmentId, undefined, t),
    [dispatchTestShipmentGet, protocolId, testShipmentId, t]
  );

  useEffect(() => {
    if (edlmDataDocument) {
      const getElmData = async () => {
        const d = await downloadFile(edlmDataDocument, true);
        // eslint-disable-next-line no-undef
        Papa.parse(d, {
          header: true,
          complete: results => {
            setEdlmData(
              sortBy(
                results.data
                  .filter(a => a.serialNumber)
                  .map(a => {
                    const ft = a.timestamp?.length === 16 ? `${a.timestamp}:00` : a.timestamp;

                    const dateTime = DateTime.fromFormat(ft, 'dd-MM-yy HH:mm:ss', {
                      zone: a.timezone || 'Europe/London',
                    });

                    // Convert the DateTime to the browser's time zone
                    const dateTimeInBrowserTimeZone = dateTime.toLocal();

                    return {
                      ...a,
                      origin: {
                        isoString: dateTime.toString(),
                        timestamp: dateTime.toUnixInteger(),
                        display: dateTime.toFormat('dd-MM-yyyy HH:mm'),
                      },
                      utc: {
                        isoString: dateTimeInBrowserTimeZone.toUTC().toString(),
                        timestamp: dateTimeInBrowserTimeZone.toUTC().toUnixInteger(),
                        display: dateTimeInBrowserTimeZone.toUTC().toFormat('dd-MM-yyyy HH:mm'),
                      },
                      local: {
                        isoString: dateTimeInBrowserTimeZone.toString(),
                        timestamp: dateTimeInBrowserTimeZone.toUnixInteger(),
                        display: dateTimeInBrowserTimeZone.toFormat('dd-MM-yyyy HH:mm'),
                      },
                      [`${a.serialNumber}_temperature`]: Number(a.temperature.replace(',', '.')),
                      temperature: Number(a.temperature.replace(',', '.')),
                      originalTimestamp: a.timestamp,
                    };
                  }),
                'origin.timestamp'
              )
            );
          },
        });
      };
      getElmData();
    }
  }, [edlmDataDocument, setEdlmData]);

  const handleExportToPdf = useCallback(async () => {
    htmlToImage.toPng(document.querySelector('.recharts-wrapper')).then(dataUrl => {
      saveAs(dataUrl, 'chart.png');
    });
  }, []);

  const handleSetTemperatureTypes = useCallback(
    (_, { value }) => {
      setTemperatureTypes(value?.length ? value : null);
      setSerialNumbers(null);
    },
    [setTemperatureTypes]
  );

  const handleSetSerialNumbers = useCallback(
    (_, { value }) => setSerialNumbers(value?.length ? value : null),
    [setSerialNumbers]
  );

  const milestonesData = useMemo(
    () =>
      (testShipmentFetch.value?.milestones || []).map(ms => {
        const dateTime = DateTime.fromISO(ms.timestamp, {
          zone: ms.timezone || 'Europe/London',
        });

        // Convert the DateTime to the browser's time zone
        const dateTimeInBrowserTimeZone = dateTime.toLocal();
        return {
          ...ms,
          origin: {
            isoString: dateTime.toString(),
            timestamp: dateTime.toUnixInteger(),
            display: dateTime.toFormat('dd-MM-yyyy HH:mm'),
          },
          utc: {
            isoString: dateTimeInBrowserTimeZone.toUTC().toString(),
            timestamp: dateTimeInBrowserTimeZone.toUTC().toUnixInteger(),
            display: dateTimeInBrowserTimeZone.toUTC().toFormat('dd-MM-yyyy HH:mm'),
          },
          local: {
            isoString: dateTimeInBrowserTimeZone.toString(),
            timestamp: dateTimeInBrowserTimeZone.toUnixInteger(),
            display: dateTimeInBrowserTimeZone.toFormat('dd-MM-yyyy HH:mm'),
          },
        };
      }),
    [testShipmentFetch.value?.milestones]
  );

  const yAxisDomain = useYAxisDomain(protocolFetch.value, edlmData);
  const xAxisDomain = useXAxisDomain(edlmData, milestonesData);

  const milestones = useMemo(
    () => createMilestones(milestonesData, xAxisDomain, yAxisDomain),
    [milestonesData, xAxisDomain, yAxisDomain]
  );

  const serialNumberOptions = useMemo(
    () =>
      (testShipmentFetch.value?.edlms || [])
        .map(edlm => ({
          ...edlm,
          key: edlm.serialNumber,
          value: edlm.serialNumber,
          text: edlm.serialNumber,
        }))
        .filter(e => !temperatureTypes || temperatureTypes.find(tt => tt === e.attachedOn)),
    [temperatureTypes, testShipmentFetch.value]
  );

  const groupedData = useMemo(
    () =>
      (edlmData || []).reduce((acc, dataPoint) => {
        const { serialNumber: dataSerialNumber } = dataPoint;
        if (!acc[dataSerialNumber]) {
          acc[dataSerialNumber] = [];
        }
        acc[dataSerialNumber].push(dataPoint);
        return acc;
      }, {}),
    [edlmData]
  );

  const xAxisTickCount = useMemo(() => {
    const dataLength = uniqBy(edlmData, 'timestamp')?.length;
    if (dataLength > 40) return 40;
    return dataLength;
  }, [edlmData]);

  const legendData = useMemo(
    () => ({
      milestones: (milestonesData || []).map((m, i) => ({
        value: `${i + 1}: ${m.name}`,
        id: i,
        color: '#2185d0',
      })),
      serialNumbers: Object.keys(groupedData)
        .map((number, i) => ({
          value: number,
          id: i,
          color: COLORS[i],
        }))
        .filter(sn =>
          serialNumbers
            ? serialNumbers.find(s => s === sn.value)
            : serialNumberOptions.find(s => s.value === sn.value)
        ),
    }),
    [milestonesData, serialNumbers, serialNumberOptions, groupedData]
  );

  if (
    !edlmData &&
    !protocolFetch.pending &&
    !testShipmentFetch.pending &&
    protocolFetch.fulfilled &&
    testShipmentFetch.fulfilled &&
    isArray(edlmData)
  ) {
    return (
      <>
        <PageTitle>
          <Flex alignItems="center">
            <Link to={`/protocol/${protocolId}/test-shipments`}>{t('testShipments')}</Link>
            &nbsp;/&nbsp;
            {t('dataOfTestShipment')}
          </Flex>
        </PageTitle>
        <PageContent>
          <Message error icon="chart area" header={t('noEdlmData')} content={t('noEdlmDataInfo')} />
        </PageContent>
      </>
    );
  }

  return (
    <>
      <PageTitle>
        <Flex alignItems="center">
          <Link to={`/protocol/${protocolId}/test-shipments`}>{t('testShipments')}</Link>
          &nbsp;/&nbsp;
          {t('dataOfTestShipment')}
        </Flex>
      </PageTitle>
      <PageContent>
        {protocolFetch.pending || testShipmentFetch.pending ? (
          <Flex fluid justifyContent="center">
            <Loader active inline content={t('loadingChart')} />
          </Flex>
        ) : (
          <>
            <Flex fluid justifyContent="space-between" alignItems="center">
              <Flex fluid alignItems="center">
                <Flex margin="0 0.5rem 0 0">{t('refineChart')}</Flex>
                <Dropdown
                  placeholder={t('temperatureType')}
                  options={TEMPERATURE_TYPE_OPTIONS}
                  value={temperatureTypes}
                  onChange={handleSetTemperatureTypes}
                  multiple
                  clearable
                  search
                  selection
                />
                <Flex margin="0 0.5rem 0 0" />
                <Dropdown
                  placeholder={t('selectASerialNumber')}
                  options={serialNumberOptions}
                  value={serialNumbers}
                  onChange={handleSetSerialNumbers}
                  multiple
                  clearable
                  search
                  selection
                />
              </Flex>
              <Flex justifyContent="flex-end">
                <Dropdown
                  fluid
                  text={t('export')}
                  icon="download"
                  className="primary icon"
                  floating
                  labeled
                  button
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleExportToPdf}>{t('toPng')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Flex>
            </Flex>
            <Divider />
            <ChartStyleWrapper>
              <ResponsiveContainer>
                <LineChart
                  id="chart"
                  data={groupedData}
                  margin={{ top: 20, right: 20, bottom: 20, left: -40 }}
                >
                  <Legend payload={legendData} content={CustomLegend} />
                  <CartesianGrid
                    stroke="#aaa" // Grid line color
                    strokeWidth="1"
                    strokeOpacity="0.35"
                    strokeDasharray="5 10" // Dashed grid lines
                  />
                  <XAxis
                    domain={xAxisDomain}
                    type="number"
                    dataKey="local.timestamp"
                    angle={-90}
                    textAnchor="end"
                    tick={{ fontSize: 12 }}
                    interval={0}
                    height={120}
                    tickCount={xAxisTickCount}
                    tickFormatter={formatTimestamp}
                    tickLine={false}
                  />
                  <YAxis
                    interval={0}
                    domain={yAxisDomain}
                    tickCount={xAxisTickCount / 5}
                    tickMargin={-2}
                    tick={{ fontSize: 12 }}
                    dataKey="temperature"
                    tickLine={false}
                    tickFormatter={formatTemperature}
                  />
                  <Tooltip
                    content={
                      <ChartToolTip serialNumbers={serialNumbers} groupedData={groupedData} />
                    }
                  />
                  {milestones}
                  {!serialNumbers &&
                    Object.keys(groupedData).map((number, i) => (
                      <Line
                        dot={false}
                        activeDot={Object.keys(groupedData).length <= 1}
                        key={`line_${number}`}
                        type="monotone"
                        dataKey={`${number}_temperature`}
                        data={groupedData[number]}
                        name={`Serial Number: ${number}`}
                        strokeWidth={2}
                        stroke={
                          serialNumberOptions.find(sno => sno.value === number)
                            ? COLORS[i]
                            : 'rgba(0, 0, 0, 0)'
                        }
                      />
                    ))}
                  {serialNumbers &&
                    Object.keys(groupedData).map((number, i) => (
                      <Line
                        dot={false}
                        activeDot={serialNumbers.length <= 1}
                        key={`line_${number}`}
                        type="monotone"
                        dataKey={`${number}_temperature`}
                        data={groupedData[number]}
                        name={`Serial Number: ${number}`}
                        strokeWidth={2}
                        stroke={
                          serialNumbers.find(sn => sn === number) ? COLORS[i] : 'rgba(0, 0, 0, 0)'
                        }
                      />
                    ))}
                  {!temperatureTypes && (
                    <>
                      <ReferenceLine
                        y={protocolFetch.value?.product?.minProductTemperature}
                        label={<ReferenceLineLabel label={t('minProductTemperature')} left />}
                        stroke="red"
                      />
                      <ReferenceLine
                        y={protocolFetch.value?.product?.maxProductTemperature}
                        label={<ReferenceLineLabel label={t('maxProductTemperature')} left />}
                        stroke="red"
                      />
                      <ReferenceLine
                        y={protocolFetch.value?.minTransportTemperature}
                        label={<ReferenceLineLabel label={t('minProductTransportTemperature')} />}
                        stroke="red"
                      />
                      <ReferenceLine
                        y={protocolFetch.value?.maxTransportTemperature}
                        label={<ReferenceLineLabel label={t('maxProductTransportTemperature')} />}
                        stroke="red"
                      />
                    </>
                  )}
                  {temperatureTypes?.find(tt => tt === TEMPERATURE_TYPES.PRODUCT) &&
                    protocolFetch.value?.product?.minProductTemperature && (
                      <ReferenceLine
                        y={protocolFetch.value?.product?.minProductTemperature}
                        label={<ReferenceLineLabel label={t('minProductTemperature')} left />}
                        stroke="red"
                      />
                    )}
                  {temperatureTypes?.find(tt => tt === TEMPERATURE_TYPES.PRODUCT) &&
                    protocolFetch.value?.product?.maxProductTemperature && (
                      <ReferenceLine
                        y={protocolFetch.value?.product?.maxProductTemperature}
                        label={<ReferenceLineLabel label={t('maxProductTemperature')} left />}
                        stroke="red"
                      />
                    )}
                  {temperatureTypes?.find(tt => tt === TEMPERATURE_TYPES.TRANSPORT) &&
                    protocolFetch.value?.minTransportTemperature && (
                      <ReferenceLine
                        y={protocolFetch.value?.minTransportTemperature}
                        label={<ReferenceLineLabel label={t('minTransportTemperature')} />}
                        stroke="red"
                      />
                    )}
                  {temperatureTypes?.find(tt => tt === TEMPERATURE_TYPES.TRANSPORT) &&
                    protocolFetch.value?.maxTransportTemperature && (
                      <ReferenceLine
                        y={protocolFetch.value?.maxTransportTemperature}
                        label={<ReferenceLineLabel label={t('maxTransportTemperature')} />}
                        stroke="red"
                      />
                    )}
                </LineChart>
              </ResponsiveContainer>
            </ChartStyleWrapper>
          </>
        )}
      </PageContent>
    </>
  );
};

Charts.propTypes = {
  dispatchProtocolGet: PropTypes.func.isRequired,
  protocolFetch: PropTypes.object.isRequired,
  dispatchTestShipmentGet: PropTypes.func.isRequired,
  testShipmentFetch: PropTypes.object.isRequired,
};

export default compose(protocolService, testShipmentService)(Charts);
