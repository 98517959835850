import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import {
  PageTitle,
  PageContent,
  Button,
  Link,
  Dimmer,
  Loader,
} from 'nallian-shared-ui/lib/components';
import { showToaster } from 'nallian-shared-ui/lib/lib/showToaster';
import { Table, Params, Menu, Actions } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, PROTOCOLS_RESOURCE, ACTIONS, MONTH_OPTIONS } from '../constants';
import NewProtocol from './NewProtocol';
import EditProtocol from './EditProtocol';
import apiRoutes from '../../../app/api/routes';
import { loadFile } from '../helpers/loadFile';
import ProtocolTestShipments from './table/ProtocolTestShipments';

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

const ProtocolsOverview = () => {
  const { t } = useTranslation();
  const { action, id } = useParams();

  const [loading, setLoading] = useState(false);

  const handleGenerateProtocol = useCallback(
    async (_, { _id }) => {
      setLoading(true);
      await timeout(2000);
      try {
        const { data: protocolData } = await axios.get(apiRoutes.protocols(_id));
        const { data: routeData } = await axios.get(apiRoutes.routes(protocolData.routeId));

        loadFile('protocol-template.docx', (err, content) => {
          if (err) {
            throw err;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          const data = {
            routeId: protocolData?.routeId,
            routeName: protocolData?.route?.name,
            routeOriginName: protocolData?.route?.origin?.name,
            routeOriginStreetAndNumber: protocolData?.route?.origin?.streetAndNumber,
            routeOriginZipCode: protocolData?.route?.origin?.zipCode,
            routeOriginCity: protocolData?.route?.origin?.city,
            routeOriginCountry: protocolData?.route?.origin?.country,
            routeDeparturePort: protocolData?.route?.departure?.port,
            routeDepartureStreetAndNumber: protocolData?.route?.departure?.streetAndNumber,
            routeDepartureZipCode: protocolData?.route?.departure?.zipCode,
            routeDepartureCity: protocolData?.route?.departure?.city,
            routeDepartureCountry: protocolData?.route?.departure?.country,
            routeArrivalPort: protocolData?.route?.departure?.port,
            routeArrivalStreetAndNumber: protocolData?.route?.departure?.streetAndNumber,
            routeArrivalZipCode: protocolData?.route?.departure?.zipCode,
            routeArrivalCity: protocolData?.route?.departure?.city,
            routeArrivalCountry: protocolData?.route?.departure?.country,
            routeDestinationName: protocolData?.route?.destination?.name,
            routeDestinationStreetAndNumber: protocolData?.route?.destination?.streetAndNumber,
            routeDestinationZipCode: protocolData?.route?.destination?.zipCode,
            routeDestinationCity: protocolData?.route?.destination?.city,
            routeDestinationCountry: protocolData?.route?.destination?.country,
            productName: protocolData?.product?.name,
            productType: protocolData?.product?.productType?.name,
            productReference: protocolData?.product?.referenceNumber,
            topics: routeData?.topics || [],
            minProductTemperature: protocolData?.product?.minProductTemperature,
            maxProductTemperature: protocolData?.product?.maxProductTemperature,
            minProductTransportTemperature: protocolData?.minTransportTemperature,
            maxProductTransportTemperature: protocolData?.maxTransportTemperature,
            transportTemperature: 'Transport Temperature',
            customerName: protocolData?.customer?.name,
            transportMode: routeData?.transportMode?.name,
            testShipmentsMonths: protocolData?.testShipments
              ?.map(testShipment => MONTH_OPTIONS.find(m => m.key === testShipment.month)?.text)
              .join(', '),
            totalTestShipments: protocolData?.testShipments?.length,
            testShipments: (protocolData?.testShipments || []).map(testShipment => ({
              ...testShipment,
              month: MONTH_OPTIONS.find(m => m.key === testShipment.month)?.text,
            })),
            responsibilities: (protocolData?.route?.responsibilities || []).map(r => ({
              ...r,
              actions: (r.actions || []).map(a => `- ${a.action}\n`).join(''),
            })),
            totalEdlms: protocolData?.edlms?.length,
            edlms: (protocolData?.edlms || []).map(e => ({
              id: e.id,
              edlm: e.edlm,
              measuringInterval: e.measuringInterval,
              startDelay: e.startDelay,
              timeZone: e.timeZone,
              name: e.edlm?.name,
              manufacturer: e.edlm?.manufacturer,
            })),
            packagingCompany: protocolData?.product?.productPackaging?.company,
            packagingUrl: protocolData?.product?.productPackaging?.url,
            packagingType: protocolData?.product?.productPackaging?.name,
            packagingQualifiedTemperature: '12°',
            packagingQualifiedTemperatureDuration: '2',
            contactPersonName: protocolData?.contactPerson?.name,
            contactPersonEmail: protocolData?.contactPerson?.emailAddress,
            contactPersonPhoneNumber: protocolData?.contactPerson?.mobileNumber,
            contactPersonCompany: protocolData?.contactPerson?.company,
            criterias: protocolData?.acceptanceCriterias,
            protocolIntroduction: protocolData?.introduction,
            protocolConclusion: protocolData?.conclusion,
          };
          doc.setData(data);
          doc.render();
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }); // Output the document using Data-URI
          saveAs(out, 'output.docx');
        });
      } catch (err) {
        showToaster('error', t('couldntGenerateProtocolDocument'));
        throw Error(err);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, t]
  );

  return (
    <>
      <Dimmer page active={loading}>
        <Loader content={t('generatingProtocolDocument')} />
      </Dimmer>
      <PageTitle>{t('protocols_plural')}</PageTitle>
      <PageContent>
        <Link to={`/protocols/${ACTIONS.ADD}`}>
          <Button btnprimary icon="plus" content={t('createProtocol')} />
        </Link>
        <NewProtocol open={action && !id} />
        <EditProtocol open={action && id} />
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={PROTOCOLS_RESOURCE}
          url={apiRoutes.protocols()}
          idProp="_id"
          pageSize={50}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url="/protocols/edit/:_id"
          />
          <Params.String name="routeName" label="route" width="11rem" noFilter grow />
          <Params.Custom
            name="testShipments"
            width="11rem"
            content={ProtocolTestShipments}
            noFilter
            grow
          />
          <Params.String name="customerName" label="customer" width="11rem" noFilter grow />
          <Params.String name="productName" label="product" width="11rem" noFilter grow />
          <Params.String name="packagingName" label="packaging" width="11rem" noFilter grow />
          <Menu width="3.5rem" align="center" alignItems="center">
            <Actions.Button
              onClick={handleGenerateProtocol}
              label="generateProtocolDocument"
              icon="sync"
            />
            <Actions.Delete
              url={apiRoutes.protocols(':_id')}
              confirmMessage={t('areYouSureRemoveProtocol')}
            />
          </Menu>
        </Table>
      </PageContent>
    </>
  );
};

export default ProtocolsOverview;
