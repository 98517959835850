import { enhanceUri } from 'nallian-shared-ui/lib/lib/url';
import settings from 'nallian-shared-ui/lib/config/appsettings';

const baseApiUrl = settings.organisationApiBaseUrl;
const enhancedBaseApiUrl = enhanceUri('v2', baseApiUrl);
const apiBase =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3001'
    : 'https://nal-lane-risk-dev.azurewebsites.net';

export default {
  apps: () => `${enhancedBaseApiUrl}/apps`,
  tenants: () => `${enhancedBaseApiUrl}/tenants`,
  protocols: id => `${apiBase}/protocols${id ? `/${id}` : ''}`,
  approveProtocol: id => `${apiBase}/protocols${id}/approve`,
  routes: id => `${apiBase}/routes${id ? `/${id}` : ''}`,
  customers: id => `${apiBase}/customers${id ? `/${id}` : ''}`,
  edlms: id => `${apiBase}/edlms${id ? `/${id}` : ''}`,
  transportModes: id => `${apiBase}/transport-modes${id ? `/${id}` : ''}`,
  transitTypes: id => `${apiBase}/transit-types${id ? `/${id}` : ''}`,
  laneTypes: id => `${apiBase}/lane-types${id ? `/${id}` : ''}`,
  productPhases: id => `${apiBase}/product-phases${id ? `/${id}` : ''}`,
  productPackagings: id => `${apiBase}/product-packagings${id ? `/${id}` : ''}`,
  acceptanceCriterias: id => `${apiBase}/acceptance-criterias${id ? `/${id}` : ''}`,
  products: id => `${apiBase}/products${id ? `/${id}` : ''}`,
  packagings: id => `${apiBase}/packagings${id ? `/${id}` : ''}`,
  timezones: () => `${enhancedBaseApiUrl}/timezones`,
  agents: id => `${apiBase}/agents${id ? `/${id}` : ''}`,
  forwarders: id => `${apiBase}/forwarders${id ? `/${id}` : ''}`,
  productTypes: id => `${apiBase}/product-types${id ? `/${id}` : ''}`,
  blobs: id => `${apiBase}/blobs${id ? `/${id}` : ''}`,
  blobDownload: () => `${apiBase}/blobs/download`,
  testShipments: (protocolId, testShipmentId) =>
    `${apiBase}/protocols/${protocolId}/test-shipments${
      testShipmentId ? `/${testShipmentId}` : ''
    }`,
};
