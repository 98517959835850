import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateProductPackagingService from '../services/updateProductPackagingService';
import productPackagingService from '../services/productPackagingService';
import ProductPackagingForm from './ProductPackagingForm';

const EditProductPackaging = ({
  open,
  dispatchUpdateProductPackagingPut,
  updateProductPackagingFetch,
  dispatchProductPackagingGet,
  productPackagingFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateProductPackagingPut(
        id,
        body,
        () => history.push('/management/product-packagings'),
        undefined,
        t
      ),
    [id, dispatchUpdateProductPackagingPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchProductPackagingGet(id, () => history.push('/management/product-packagings'), t);
    }
  }, [id, open, t, dispatchProductPackagingGet]);

  const defaultData = useMemo(
    () =>
      !productPackagingFetch.pending && {
        ...(productPackagingFetch.value || {}),
      },
    [productPackagingFetch]
  );

  if (!open) return null;

  return (
    <ProductPackagingForm
      header={productPackagingFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateProductPackagingFetch.pending}
      returnRoute="/management/product-packagings"
      cancelRoute="/management/product-packagings"
      dataLoading={productPackagingFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditProductPackaging.propTypes = {
  dispatchUpdateProductPackagingPut: PropTypes.func.isRequired,
  updateProductPackagingFetch: PropTypes.object.isRequired,
  dispatchProductPackagingGet: PropTypes.func.isRequired,
  productPackagingFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditProductPackaging.defaultProps = { open: false };

export default compose(
  productPackagingService,
  updateProductPackagingService
)(EditProductPackaging);
