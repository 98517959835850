import axios from 'axios';
import FileSaver from 'file-saver';
import apiRoutes from '../../../app/api/routes';

export const downloadFile = async (filename, returnData = false) => {
  try {
    const { data } = await axios.post(
      apiRoutes.blobDownload(),
      { filename },
      {
        headers: { Accept: 'application/octet-stream' },
        responseType: 'blob',
      }
    );

    if (data && !returnData) {
      FileSaver.saveAs(data, filename);
      return true;
    }
    if (data && returnData) {
      return data;
    }

    return false;
  } catch (err) {
    throw new Error(err);
  }
};
