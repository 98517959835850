import { useMemo } from 'react';
import min from 'lodash/min';
import max from 'lodash/max';

export const useYAxisDomain = (protocol = {}, data) =>
  useMemo(() => {
    const { product, minTransportTemperature, maxTransportTemperature } = protocol;
    const temperatures = [
      product?.minProductTemperature,
      product?.maxProductTemperature,
      minTransportTemperature,
      maxTransportTemperature,
      ...(data || []).map(d => d.temperature),
    ].filter(t => t);
    const minTemperature = min(temperatures);
    const maxTemperature = max(temperatures);

    return [minTemperature - 7, maxTemperature + 10];
  }, [protocol, data]);
