import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createProductService from '../services/createProductService';
import ProductForm from './ProductForm';
import { mapFormToProduct } from '../helpers/mapFormToProduct';

const EMPTY_PRODUCT = { name: '' };

const NewProduct = ({ open, dispatchCreateProductPost, createProductFetch }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body =>
      dispatchCreateProductPost(
        mapFormToProduct(body),
        () => history.push('/management/products'),
        undefined,
        t
      ),
    [dispatchCreateProductPost, t]
  );

  if (!open) return null;

  return (
    <ProductForm
      header={t('createProduct')}
      onSubmit={handleSubmit}
      submitProcessing={createProductFetch.pending}
      returnRoute="/management/products"
      cancelRoute="/management/products"
      data={EMPTY_PRODUCT}
    />
  );
};

NewProduct.propTypes = {
  dispatchCreateProductPost: PropTypes.func.isRequired,
  createProductFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewProduct.defaultProps = { open: false };

export default createProductService(NewProduct);
