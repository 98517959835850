import Scene from '../scenes/ProductsScene';

const routes = [
  {
    path: '/management/products',
    menuName: 'products_plural',
    component: Scene,
  },
];

export default routes;
