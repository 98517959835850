import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { F, Flex, Button } from 'nallian-shared-ui/lib/components';
import EdlmField from './EdlmField';
import { FieldFlex } from '../../../app/styles';

const EdlmFields = ({ submitProcessing, dataLoading, fields, protocol }) => {
  const { t } = useTranslation();

  const handleAddFieldClick = useCallback(() => fields.push({ isNew: true }), [fields]);

  const edlmOptions = useMemo(() => {
    const edlms = [];
    (protocol?.edlms || []).forEach(e =>
      edlms.push({ key: e.edlm._id, value: e.edlm._id, text: e.edlm.name })
    );

    return uniqWith(edlms, isEqual);
  }, [protocol]);

  return (
    <F.Field fluid error={false}>
      <Flex fluid direction="column">
        <FieldFlex fluid direction="column">
          {!fields?.length && <Flex>{t('noEdlmsDefined')}</Flex>}
          {fields.map((f, index) => (
            <EdlmField
              field={f}
              key={f}
              index={index}
              submitProcessing={submitProcessing}
              dataLoading={dataLoading}
              protocol={protocol}
              fields={fields}
              edlmOptions={edlmOptions}
            />
          ))}
        </FieldFlex>
        <Flex fluid justifyContent="flex-end" margin="3rem 0 2rem 0">
          <Button
            linkbutton
            icon="add"
            type="button"
            tabindex="-1"
            content={t('addSerialNumber')}
            onClick={handleAddFieldClick}
          />
        </Flex>
      </Flex>
    </F.Field>
  );
};

EdlmFields.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  protocol: PropTypes.object,
};

EdlmFields.defaultProps = { protocol: undefined };

export default EdlmFields;
