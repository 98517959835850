import React from 'react';
import { Route } from 'react-router-dom';
import ProtocolsOverview from '../components/ProtocolsOverview';
import TestShipmentsOverview from '../../testShipments/components/TestShipmentsOverview';
import TestShipmentCharts from '../../charts/components/Charts';

const ProtocolsScene = () => (
  <>
    <Route
      exact
      path="/protocol/:id/test-shipments/:subResourceId/charts"
      component={TestShipmentCharts}
    />
    <Route
      exact
      path="/protocol/:id/test-shipments/:subResourceAction?/:subResourceId?"
      component={TestShipmentsOverview}
    />
    <Route
      exact
      path="/protocols/:action?/:id?"
      component={ProtocolsOverview}
      menuName="protocols_plural"
    />
  </>
);

export default ProtocolsScene;
