import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const AgentsScene = () => (
  <>
    <Route exact path="/management/agents/:id?" menuName="agents_plural" component={Overview} />
  </>
);

export default AgentsScene;
