import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageTitle, PageContent, Button, Link } from 'nallian-shared-ui/lib/components';
import { Table, Params, Menu, Actions } from 'nallian-table-builder-ui';
import { INFINITE_SCROLL, ROUTES_RESOURCE, ACTIONS } from '../constants';
import NewRoute from './NewRoute';
import EditRoute from './EditRoute';
import apiRoutes from '../../../app/api/routes';
import Location from './table/Location';

const RoutesOverview = () => {
  const { t } = useTranslation();
  const { action, id } = useParams();
  return (
    <>
      <PageTitle>{t('routes_plural')}</PageTitle>
      <PageContent>
        <Link to={`/routes/${ACTIONS.ADD}`}>
          <Button btnprimary icon="plus" content={t('createRoute')} />
        </Link>
        <NewRoute open={action && !id} />
        <EditRoute open={action && id} />
        <Table
          infiniteScroll={INFINITE_SCROLL}
          resource={ROUTES_RESOURCE}
          url={apiRoutes.routes()}
          idProp="_id"
          pageSize={50}
          rowHeight={52}
          resizable
          noUserSettings
          sorted
        >
          <Actions.Link
            iconbutton
            width="2.5rem"
            icon="eye"
            align="center"
            url="/routes/edit/:_id"
          />
          <Params.String name="name" width="11rem" noFilter grow />
          <Params.String
            name="transportModeName"
            label="transportMode"
            width="11rem"
            noFilter
            grow
          />
          <Params.String name="laneTypeName" label="laneType" width="11rem" noFilter grow />
          <Params.Custom
            name="origin"
            fallback="departure"
            content={Location}
            width="11rem"
            noFilter
            grow
          />
          <Params.Custom
            name="destination"
            fallback="arrival"
            content={Location}
            width="11rem"
            noFilter
            grow
          />
          <Menu width="3.5rem" align="center" alignItems="center">
            <Actions.Delete
              url={apiRoutes.routes(':_id')}
              confirmMessage={t('areYouSureRemoveRoute')}
            />
          </Menu>
        </Table>
      </PageContent>
    </>
  );
};

export default RoutesOverview;
