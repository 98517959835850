import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Field, Flex, Button } from 'nallian-shared-ui/lib/components';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import pick from 'lodash/pick';
import compose from 'recompose/compose';
import { FieldArray, getFormSyncErrors } from 'redux-form';
import { useSelector } from 'react-redux';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import {
  InputFieldValueComponent,
  CheckBoxFieldValueComponent,
  SelectFieldValueComponent,
  FileFieldValueComponent,
  LookupFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { history } from 'nallian-shared-ui/lib/config/history';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import { FormModal } from 'nallian-shared-ui/lib/modules';
import { countryCodesForSelectBox } from 'nallian-shared-ui/lib/lib/countrycodes';
import { FORM_NAME } from '../constants';
import TransitFields from './form/TransitFields';
import ResponsibilityFields from './form/ResponsibilityFields';
import FormHeader from '../../app/components/FormHeader';
import TopicFields from './form/TopicFields';
import { Step, Hidden, StepNumber } from '../../app/styles';
import apiRoutes from '../../../app/api/routes';
import { downloadFile } from '../../app/helpers/downloadFile';

const FIRST_TAB_FIELDS = ['name', 'timeZone', 'transportModeId', 'laneTypeId', 'topics'];
const SECOND_TAB_FIELDS = [
  'origin.name',
  'origin.streetAndNumber',
  'origin.zipCode',
  'origin.city',
  'origin.country',
  'departure.port',
  'departure.streetAndNumber',
  'departure.zipCode',
  'departure.city',
  'departure.country',
  'arrival.port',
  'arrival.streetAndNumber',
  'arrival.zipCode',
  'arrival.city',
  'arrival.country',
  'destination.name',
  'destination.streetAndNumber',
  'destination.zipCode',
  'destination.city',
  'destination.country',
];

const REQUIRED_FIELDS = [...FIRST_TAB_FIELDS, ...SECOND_TAB_FIELDS];
const TRANSIT_REQUIRED_FIELDS = ['typeId', 'port'];
const RESPONSIBILITIES_REQUIRED_FIELDS = ['step'];
const TOPIC_REQUIRED_FIELDS = ['topic'];

const STEPS = { INFO: 'INFO', STEPS: 'STEPS', RESPONSIBILITIES: 'RESPONSIBILITIES' };

const validate = values => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);
  (values.transits || []).forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      TRANSIT_REQUIRED_FIELDS.map(f => ({ fieldKey: `transits[${i}].${f}`, key: f })),
      values,
      errors
    );
  });

  (values.responsibilities || []).forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      RESPONSIBILITIES_REQUIRED_FIELDS.map(f => ({
        fieldKey: `responsibilities[${i}].${f}`,
        key: f,
      })),
      values,
      errors
    );
  });

  (values.topics || []).forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      TOPIC_REQUIRED_FIELDS.map(f => ({
        fieldKey: `topics[${i}].${f}`,
        key: f,
      })),
      values,
      errors
    );
  });

  return errors;
};

const RouteForm = ({
  timezonesFetch,
  forwardersFetch,
  submitProcessing,
  dataLoading,
  data,
  returnRoute,
  onSubmit,
  header,
  agentsFetch,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEPS.INFO);

  const handleSetStep = useCallback((_, item) => setStep(item.step), [setStep]);

  const handlePreviousStep = useCallback(() => {
    const keys = Object.keys(STEPS);
    const nextIndex = keys.indexOf(step) - 1;
    setStep(keys[nextIndex]);
  }, [step, setStep]);

  const handleNextStep = useCallback(() => {
    const keys = Object.keys(STEPS);
    const nextIndex = keys.indexOf(step) + 1;
    setStep(keys[nextIndex]);
  }, [step, setStep]);

  const handleGoBack = useCallback(() => history.push(returnRoute), [returnRoute]);

  const timezoneOptions = useMemo(
    () => (timezonesFetch.value || []).map(v => ({ key: v, value: v, text: v })),
    [timezonesFetch.value]
  );

  const forwarderOptions = useMemo(
    () => (forwardersFetch.value || []).map(f => ({ key: f._id, value: f._id, text: f.name })),
    [forwardersFetch.value]
  );

  const showForwarderField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.forwarder.enabled`, false)
  );

  const isLastStep = step === STEPS.RESPONSIBILITIES;
  const isFirstStep = step === STEPS.INFO;

  const errors = useSelector(state => getFormSyncErrors(FORM_NAME)(state));
  const submitFailed = useSelector(state => get(state, `form.${FORM_NAME}.submitFailed`));
  const laneTypes = useSelector(state => get(state, `repository.laneTypeId.value`));
  const laneTypeId = useSelector(state => get(state, `form.${FORM_NAME}.values.laneTypeId`));

  const departureShowHandlingAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.departure.handlingAgent.enabled`, false)
  );

  const arrivalShowHandlingAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.arrival.handlingAgent.enabled`, false)
  );

  const departureShowRampAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.departure.rampHandling.enabled`, false)
  );

  const arrivalShowRampAgentField = useSelector(state =>
    get(state, `form.${FORM_NAME}.values.arrival.rampHandling.enabled`, false)
  );

  const firstTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, FIRST_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  const secondTabsErrors = useMemo(
    () => submitFailed && Object.keys(pick(errors, SECOND_TAB_FIELDS)).length > 0,
    [errors, submitFailed]
  );

  const agentOptions = useMemo(
    () =>
      (agentsFetch.value || []).map(agent => ({
        key: agent._id,
        value: agent._id,
        text: agent.name,
      })),
    [agentsFetch.value]
  );

  const selectedLaneType = useMemo(
    () => (laneTypes || []).find(l => l._id === laneTypeId),
    [laneTypes, laneTypeId]
  );

  const handleDownloadCriticalPoints = useCallback(
    () => downloadFile(data?.criticalPointsDocument),
    [data]
  );

  const hideOrigin =
    selectedLaneType?.name === 'Port to door' || selectedLaneType?.name === 'Port to port';
  const hideDestination =
    selectedLaneType?.name === 'Door to port' || selectedLaneType?.name === 'Port to port';

  const countries = countryCodesForSelectBox();

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      customOnSubmit={!isLastStep ? handleNextStep : undefined}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      onDimmerClick={handleGoBack}
      onCancel={!isFirstStep ? handlePreviousStep : handleGoBack}
      cancelButtonContent={!isFirstStep ? t('back') : t('cancel')}
      saveButtonIcon={isLastStep ? 'save' : 'arrow right'}
      saveButtonContent={isLastStep ? t('save') : t('next')}
      saveButtonIconPosition={isLastStep ? 'left' : 'right'}
    >
      <Flex margin="-0.5rem 0" />
      <Step.Group fluid>
        <Step
          fluid
          link
          active={step === STEPS.INFO}
          step={STEPS.INFO}
          onClick={handleSetStep}
          width="calc(100% / 3)"
          error={firstTabsErrors}
        >
          <StepNumber>1</StepNumber>
          <Step.Content>
            <Step.Title>{t('routeInformation')}</Step.Title>
            <Step.Description>{t('routeInformationDescription')}</Step.Description>
          </Step.Content>
        </Step>
        <Step
          fluid
          link
          active={step === STEPS.STEPS}
          step={STEPS.STEPS}
          onClick={handleSetStep}
          width="calc(100% / 3)"
          error={secondTabsErrors}
        >
          <StepNumber>2</StepNumber>
          <Step.Content>
            <Step.Title>{t('routeSteps')}</Step.Title>
            <Step.Description>{t('routeStepsDescription')}</Step.Description>
          </Step.Content>
        </Step>
        <Step
          fluid
          link
          active={step === STEPS.RESPONSIBILITIES}
          step={STEPS.RESPONSIBILITIES}
          onClick={handleSetStep}
          width="calc(100% / 3)"
        >
          <StepNumber>3</StepNumber>
          <Step.Content>
            <Step.Title>{t('routeResponsibilities')}</Step.Title>
            <Step.Description>{t('routeResponsibilitiesDescription')}</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      <Hidden hidden={step !== STEPS.INFO}>
        <FormHeader content="Route" />
        <Grid>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('name')}
              name="name"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('timeZone')}
              name="timeZone"
              options={timezoneOptions}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('transportMode')}
              name="transportModeId"
              valueProp="_id"
              url={apiRoutes.transportModes()}
              valueComponent={LookupFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              clearable
              label={t('laneType')}
              name="laneTypeId"
              valueProp="_id"
              url={apiRoutes.laneTypes()}
              valueComponent={LookupFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Route topics" />
        <FieldArray name="topics" component={TopicFields} />
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Forwarder" />
        <Grid>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              toggle
              label={t('hasForwarder')}
              name="forwarder.enabled"
              valueProp="_id"
              valueComponent={CheckBoxFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          {showForwarderField && (
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <Field
                fluid
                label={t('forwarder')}
                name="forwarder.id"
                options={forwarderOptions}
                valueComponent={SelectFieldValueComponent}
                disabled={submitProcessing || dataLoading}
              />
            </Grid.Column>
          )}
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader
          fluid
          content={
            <Flex fluid alignItems="center" justifyContent="space-between">
              <Flex>Critical points document</Flex>
              {data.criticalPointsDocument && (
                <Button
                  btnprimary
                  size="small"
                  onClick={handleDownloadCriticalPoints}
                  icon="download"
                  content="Download"
                />
              )}
            </Flex>
          }
        />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              fluid
              toggle
              name="criticalPointsDocument"
              valueComponent={FileFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
        </Grid>
      </Hidden>
      <Hidden hidden={step !== STEPS.STEPS}>
        {!hideOrigin && (
          <>
            <FormHeader content="Origin" />
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Field
                  fluid
                  label={t('nameOrCompanyName')}
                  name="origin.name"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('streetAndNumber')}
                  name="origin.streetAndNumber"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('zipCode')}
                  name="origin.zipCode"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('city')}
                  name="origin.city"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  search
                  label={t('country')}
                  name="origin.country"
                  options={countries}
                  valueComponent={SelectFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
            </Grid>
          </>
        )}
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Departure" />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              fluid
              label={t('port')}
              name="departure.port"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('streetAndNumber')}
              name="departure.streetAndNumber"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('zipCode')}
              name="departure.zipCode"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('city')}
              name="departure.city"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              label={t('country')}
              name="departure.country"
              options={countries}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              toggle
              label={t('hasHandlingAgent')}
              name="departure.handlingAgent.enabled"
              valueComponent={CheckBoxFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            {departureShowHandlingAgentField && (
              <Field
                fluid
                search
                clearable
                label={t('agent')}
                name="departure.handlingAgent.id"
                options={agentOptions}
                valueComponent={SelectFieldValueComponent}
                disabled={submitProcessing || dataLoading}
              />
            )}
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              toggle
              label={t('hasRampAgent')}
              name="departure.rampHandling.enabled"
              valueComponent={CheckBoxFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            {departureShowRampAgentField && (
              <Field
                fluid
                search
                clearable
                label={t('agent')}
                name="departure.rampHandling.id"
                options={agentOptions}
                valueComponent={SelectFieldValueComponent}
                disabled={submitProcessing || dataLoading}
              />
            )}
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Transits" />
        <FieldArray name="transits" component={TransitFields} />
        <Flex fluid margin="1rem 0 0" />
        <FormHeader content="Arrival" />
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              fluid
              label={t('port')}
              name="arrival.port"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('streetAndNumber')}
              name="arrival.streetAndNumber"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('zipCode')}
              name="arrival.zipCode"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              label={t('city')}
              name="arrival.city"
              valueComponent={InputFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              search
              label={t('country')}
              name="arrival.country"
              options={countries}
              valueComponent={SelectFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              toggle
              label={t('hasHandlingAgent')}
              name="arrival.handlingAgent.enabled"
              valueComponent={CheckBoxFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            {arrivalShowHandlingAgentField && (
              <Field
                fluid
                search
                clearable
                label={t('agent')}
                name="arrival.handlingAgent.id"
                options={agentOptions}
                valueComponent={SelectFieldValueComponent}
                disabled={submitProcessing || dataLoading}
              />
            )}
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Field
              fluid
              toggle
              label={t('hasRampAgent')}
              name="arrival.rampHandling.enabled"
              valueComponent={CheckBoxFieldValueComponent}
              disabled={submitProcessing || dataLoading}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            {arrivalShowRampAgentField && (
              <Field
                fluid
                search
                clearable
                label={t('agent')}
                name="arrival.rampHandling.id"
                options={agentOptions}
                valueComponent={SelectFieldValueComponent}
                disabled={submitProcessing || dataLoading}
              />
            )}
          </Grid.Column>
        </Grid>
        <Flex fluid margin="1rem 0 0" />
        {!hideDestination && (
          <>
            <FormHeader content="Destination" />
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Field
                  fluid
                  label={t('nameOrCompanyName')}
                  name="destination.name"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('streetAndNumber')}
                  name="destination.streetAndNumber"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('zipCode')}
                  name="destination.zipCode"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  label={t('city')}
                  name="destination.city"
                  valueComponent={InputFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8}>
                <Field
                  fluid
                  search
                  label={t('country')}
                  name="destination.country"
                  options={countries}
                  valueComponent={SelectFieldValueComponent}
                  disabled={submitProcessing || dataLoading}
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </Hidden>
      <Hidden hidden={step !== STEPS.RESPONSIBILITIES}>
        <FormHeader content="Responsibilities" />
        <FieldArray name="responsibilities" component={ResponsibilityFields} />
      </Hidden>
    </FormModal>
  );
};

RouteForm.propTypes = {
  timezonesFetch: PropTypes.object.isRequired,
  forwardersFetch: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  agentsFetch: PropTypes.object.isRequired,
  data: PropTypes.object,
};

RouteForm.defaultProps = {
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default compose(
  lookupService({
    resource: 'timezones',
    url: apiRoutes.timezones(),
  }),
  lookupService({
    resource: 'forwarders',
    url: apiRoutes.forwarders(),
  }),
  lookupService({
    resource: 'agents',
    url: apiRoutes.agents(),
  })
)(RouteForm);
