import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { CREATE_CUSTOMER_RESOURCE, CUSTOMERS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const CreateCustomerService = ComposedComponent =>
  reduxFetch([
    {
      resource: CREATE_CUSTOMER_RESOURCE,
      method: 'post',
      request: (body, callback, rejectCallback, t) => ({
        url: apiRoutes.customers(),
        body,
        meta: {
          callback,
          rejectCallback,
          addToOtherLists: [
            {
              resource: CUSTOMERS_RESOURCE,
              idProp: '_id',
            },
            {
              // lookup field
              resource: 'customerId',
              idProp: '_id',
            },
          ],
          toaster: {
            onErrorMessage: createErrorMessage(t, 'createCustomerFailed'),
            onSuccessMessage: t('createCustomerSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default CreateCustomerService;
