import RoutesScene from '../scenes/RoutesScene';

const routes = [
  {
    path: '/routes',
    menuName: 'routes_plural',
    component: RoutesScene,
  },
];

export default routes;
