import { Translation } from 'react-i18next';

export const TEMPERATURE_TYPES = {
  PRODUCT: 'product',
  TRANSPORT: 'transport',
};

export const TEMPERATURE_TYPE_OPTIONS = Object.keys(TEMPERATURE_TYPES).map(k => ({
  key: TEMPERATURE_TYPES[k],
  value: TEMPERATURE_TYPES[k],
  text: <Translation>{t => t(TEMPERATURE_TYPES[k])}</Translation>,
}));
