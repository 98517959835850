import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import set from 'lodash/set';
import { useParams } from 'react-router-dom';
import { history } from 'nallian-shared-ui/lib/config/history';
import { Grid } from 'nallian-shared-ui/lib/components';
import { FieldArray } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import { FormModal } from 'nallian-shared-ui/lib/modules';
import MilestonesFields from './MilestonesFields';
import updateTestShipmentService from '../../../services/updateTestShipmentService';
import testShipmentService from '../../../services/testShipmentService';

const MILESTONES_REQUIRED_FIELDS = ['name', 'companyName', 'timestamp', 'timezone'];
const FORM_NAME = 'milestones';

const validate = (values, { t }) => {
  let errors = {};

  values?.milestones.forEach((_, i) => {
    errors = generateErrorsForRequiredFields(
      MILESTONES_REQUIRED_FIELDS.map(f => ({ fieldKey: `milestones[${i}].${f}`, key: f })),
      values,
      errors
    );
    if (!_?.iata || !_?.location) {
      set(errors, `milestones[${i}].location`, t('enterLocationOrIata'));
      set(errors, `milestones[${i}].iata`, t('enterLocationOrIata'));
    }
  });

  return errors;
};

const MilestonesForm = ({
  dispatchUpdateTestShipmentPut,
  updateTestShipmentFetch,
  dispatchTestShipmentGet,
  testShipmentFetch,
}) => {
  const { t } = useTranslation();
  const { id, subResourceId } = useParams();

  useEffect(
    () =>
      dispatchTestShipmentGet(
        id,
        subResourceId,
        () => history.push(`/protocols/${id}/test-shipments`),
        t
      ),
    [dispatchTestShipmentGet, t, id, subResourceId]
  );

  const handleSubmit = useCallback(
    values => {
      dispatchUpdateTestShipmentPut(
        id,
        subResourceId,
        { ...(testShipmentFetch.value || {}), milestones: values.milestones },
        () => history.push(`/protocol/${id}/test-shipments`),
        undefined,
        t
      );
    },
    [testShipmentFetch.value, t, subResourceId, id, dispatchUpdateTestShipmentPut]
  );

  const defaultData = useMemo(
    () => ({
      ...(testShipmentFetch.value || {}),
      milestones: (testShipmentFetch.value?.milestones || []).map(m => ({
        ...m,
        name: m.name || '',
      })),
    }),
    [testShipmentFetch.value]
  );

  return (
    <FormModal
      closeIcon
      header={
        testShipmentFetch.value?.reference
          ? t('milestonesForTestShipment', { reference: testShipmentFetch.value?.reference })
          : t('milestones')
      }
      dataLoading={testShipmentFetch.pending}
      submitProcessing={updateTestShipmentFetch.pending}
      onSubmit={handleSubmit}
      data={defaultData}
      t={t}
      validate={validate}
      form={FORM_NAME}
      cancelRoute={`/protocol/${id}/test-shipments`}
      cancelButtonContent={t('cancel')}
      saveButtonIcon="save"
      saveButtonContent={t('save')}
    >
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <FieldArray name="milestones" component={MilestonesFields} />
        </Grid.Column>
      </Grid>
    </FormModal>
  );
};

MilestonesForm.propTypes = {
  dispatchUpdateTestShipmentPut: PropTypes.func.isRequired,
  updateTestShipmentFetch: PropTypes.object.isRequired,
  dispatchTestShipmentGet: PropTypes.func.isRequired,
  testShipmentFetch: PropTypes.object.isRequired,
};

MilestonesForm.defaultProps = {};

export default compose(updateTestShipmentService, testShipmentService)(MilestonesForm);
