import ProtocolsScene from '../scenes/ProtocolsScene';

const routes = [
  {
    path: '/protocols',
    menuName: 'protocols_plural',
    component: ProtocolsScene,
  },
  {
    path: '/protocol',
    component: ProtocolsScene,
  },
];

export default routes;
