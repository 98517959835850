import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Grid, Field } from 'nallian-shared-ui/lib/components';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import { useTranslation } from 'react-i18next';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import {
  InputFieldValueComponent,
  SelectFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { FormModal } from 'nallian-shared-ui/lib/modules';
import apiRoutes from '../../../../app/api/routes';

const REQUIRED_FIELDS = [
  'name',
  'referenceNumber',
  'typeId',
  'phaseId',
  'packagingId',
  'minProductTemperature',
  'maxProductTemperature',
];
const FORM_NAME = 'product';

const validate = values => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);

  return errors;
};

const ProductForm = ({
  submitProcessing,
  dataLoading,
  data,
  returnRoute,
  onSubmit,
  header,
  productTypesFetch,
  productPackagingsFetch,
  productPhasesFetch,
}) => {
  const { t } = useTranslation();

  const productTypeOptions = useMemo(
    () =>
      (productTypesFetch.value || []).map(p => ({
        value: p._id,
        key: p._id,
        text: p.name,
      })),
    [productTypesFetch.value]
  );

  const productPackagingOptions = useMemo(
    () =>
      (productPackagingsFetch.value || []).map(p => ({
        value: p._id,
        key: p._id,
        text: p.name,
      })),
    [productPackagingsFetch.value]
  );

  const productPhaseOptions = useMemo(
    () =>
      (productPhasesFetch.value || []).map(p => ({
        value: p._id,
        key: p._id,
        text: p.name,
      })),
    [productPhasesFetch.value]
  );

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      cancelRoute={returnRoute}
      cancelButtonContent={t('cancel')}
      saveButtonIcon="save"
      saveButtonContent={t('save')}
    >
      <Grid>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            label={t('name')}
            name="name"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            label={t('referenceNumber')}
            name="referenceNumber"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            type="number"
            label={t('minProductTemperature')}
            name="minProductTemperature"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            type="number"
            label={t('maxProductTemperature')}
            name="maxProductTemperature"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
      </Grid>
      <Grid columns={3}>
        <Grid.Column>
          <Field
            fluid
            label={t('typeId')}
            name="typeId"
            valueComponent={SelectFieldValueComponent}
            options={productTypeOptions}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            fluid
            label={t('packagingId')}
            name="packagingId"
            valueComponent={SelectFieldValueComponent}
            options={productPackagingOptions}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            fluid
            label={t('phaseId')}
            name="phaseId"
            valueComponent={SelectFieldValueComponent}
            options={productPhaseOptions}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
      </Grid>
    </FormModal>
  );
};

ProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  productTypesFetch: PropTypes.object.isRequired,
  productPackagingsFetch: PropTypes.object.isRequired,
  productPhasesFetch: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  data: PropTypes.object,
};

ProductForm.defaultProps = {
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default compose(
  lookupService({ resource: 'productTypes', url: apiRoutes.productTypes() }),
  lookupService({ resource: 'productPackagings', url: apiRoutes.productPackagings() }),
  lookupService({ resource: 'productPhases', url: apiRoutes.productPhases() })
)(ProductForm);
