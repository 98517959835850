import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { F, Flex, Button } from 'nallian-shared-ui/lib/components';
import TopicField from './TopicField';
import { FieldFlex } from '../../../app/styles';

const TopicFields = ({ submitProcessing, dataLoading, fields }) => {
  const { t } = useTranslation();

  const handleAddFieldClick = useCallback(() => fields.push({ isNew: true }), [fields]);

  return (
    <F.Field error={false}>
      <Flex fluid direction="column">
        <FieldFlex fluid direction="column">
          {!fields?.length && <Flex>{t('noTopicsDefined')}</Flex>}
          {fields.map((f, index) => (
            <TopicField
              field={f}
              key={f}
              index={index}
              submitProcessing={submitProcessing}
              dataLoading={dataLoading}
            />
          ))}
        </FieldFlex>
        <Flex fluid justifyContent="flex-end" margin="1rem 0 1rem 0">
          <Button
            linkbutton
            icon="add"
            type="button"
            tabindex="-1"
            content={t('addTopic')}
            onClick={handleAddFieldClick}
          />
        </Flex>
      </Flex>
    </F.Field>
  );
};

TopicFields.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
};

export default TopicFields;
