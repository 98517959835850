import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Field, Grid, Button, Flex } from 'nallian-shared-ui/lib/components';
import {
  SelectFieldValueComponent,
  InputFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { TEMPERATURE_TYPE_OPTIONS } from '../../../charts/constants';

const EdlmGrid = styled(Grid)`
  && .column {
    margin-bottom: -1.5rem;
  }

  && .field {
    margin-bottom: 0;
  }
`;

const EdlmField = ({ submitProcessing, field, edlmOptions, index, fields }) => {
  const { t } = useTranslation();

  const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <EdlmGrid stretched columns={3}>
      <Grid.Column>
        <Field
          fluid
          clearable
          label={index === 0 && t('serialNumber')}
          placeholder={t('serialNumber')}
          name={`${field}.serialNumber`}
          valueComponent={InputFieldValueComponent}
          disabled={submitProcessing}
        />
      </Grid.Column>
      <Grid.Column>
        <Field
          fluid
          search
          clearable
          label={index === 0 && t('type')}
          placeholder={t('type')}
          name={`${field}.id`}
          options={edlmOptions}
          valueComponent={SelectFieldValueComponent}
          disabled={submitProcessing}
        />
      </Grid.Column>
      <Grid.Column>
        <Flex fluid>
          <Field
            fluid
            search
            clearable
            label={index === 0 && t('attachedOn')}
            placeholder={t('attachedOn')}
            name={`${field}.attachedOn`}
            options={TEMPERATURE_TYPE_OPTIONS}
            valueComponent={SelectFieldValueComponent}
            disabled={submitProcessing}
          />
          <Button
            iconbutton
            linkbutton
            icon="close"
            type="button"
            id="removeIdCode"
            margin={index === 0 ? '1.6rem 0 0 0.3rem' : '0.1rem 0 0 0.3rem'}
            tabindex="-1"
            i={index}
            onClick={handleRemoveFieldClick}
          />
        </Flex>
      </Grid.Column>
    </EdlmGrid>
  );
};

EdlmField.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  submitProcessing: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
  edlmOptions: PropTypes.array.isRequired,
};

export default EdlmField;
