import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import { showToaster } from 'nallian-shared-ui/lib/lib/showToaster';
import createRouteService from '../services/CreateRouteService';
import routesService from '../services/RoutesService';
import RouteForm from './RouteForm';
import { mapFormToRoute } from '../helpers/mapFormToRoute';
import { uploadFile } from '../../app/helpers/uploadFile';

const EMPTY_ROUTE = { forwarder: { enabled: false } };

const NewRoute = ({ open, dispatchCreateRoutePost, dispatchRoutesGet, createRouteFetch }) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);

  const handleSubmit = useCallback(
    async body => {
      setFetching(true);
      if (body.criticalPointsDocument) {
        const uploadSuccess = await uploadFile(body.criticalPointsDocument);
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedUpdateRoute'));
          return false;
        }
      }
      dispatchCreateRoutePost(
        mapFormToRoute(body),
        () => {
          dispatchRoutesGet();
          history.push(`/routes`);
          setFetching(false);
        },
        () => setFetching(false),
        t
      );

      return true;
    },
    [dispatchCreateRoutePost, setFetching, dispatchRoutesGet, t]
  );

  if (!open) return null;

  return (
    <RouteForm
      header={t('createRoute')}
      onSubmit={handleSubmit}
      submitProcessing={createRouteFetch.pending || fetching}
      returnRoute="/routes"
      cancelRoute="/routes"
      data={EMPTY_ROUTE}
    />
  );
};

NewRoute.propTypes = {
  dispatchRoutesGet: PropTypes.func.isRequired,
  dispatchCreateRoutePost: PropTypes.func.isRequired,
  createRouteFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewRoute.defaultProps = { open: false };

export default compose(routesService, createRouteService)(NewRoute);
