import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'nallian-shared-ui/lib/components';

const ProtocolTestShipments = ({ value, item }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/protocol/${item._id}/test-shipments`}>
      {Number(value?.length)} {t('testShipments')}
    </Link>
  );
};

ProtocolTestShipments.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.array,
};
ProtocolTestShipments.defaultProps = { value: undefined };

export default ProtocolTestShipments;
