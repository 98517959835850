import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import { showToaster } from 'nallian-shared-ui/lib/lib/showToaster';
import { uploadFile } from '../../app/helpers/uploadFile';
import { renameFile } from '../../app/helpers/renameFile';
import createTestShipmentService from '../services/createTestShipmentService';
import TestShipmentForm from './TestShipmentForm';
import { mapFormToTestShipment } from '../helpers/mapFormToTestShipment';

const EMPTY_TEST_SHIPMENT = { reference: '' };

const NewTestShipment = ({
  open,
  dispatchCreateTestShipmentPost,
  createTestShipmentFetch,
  protocol,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [fetching, setFetching] = useState(false);

  const handleSubmit = useCallback(
    async body => {
      const { loadingDocument, edlmDataDocument, ...restBody } = body;
      const loadingDocumentFileName = `protocols/${id}/test-shipments/loading-document/${loadingDocument?.name}`;
      const edlmDataDocumentFileName = `protocols/${id}/test-shipments/edlm-data/${edlmDataDocument?.name}`;
      setFetching(true);
      if (loadingDocument) {
        const uploadSuccess = await uploadFile(
          renameFile(loadingDocument, loadingDocumentFileName)
        );
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedCreateTestShipment'));
          return false;
        }
      }
      if (edlmDataDocument) {
        const uploadSuccess = await uploadFile(
          renameFile(edlmDataDocument, edlmDataDocumentFileName)
        );
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedCreateTestShipment'));
          return false;
        }
      }
      dispatchCreateTestShipmentPost(
        id,
        mapFormToTestShipment({
          ...restBody,
          loadingDocument: loadingDocument ? loadingDocumentFileName : '',
          edlmDataDocument: edlmDataDocument ? edlmDataDocumentFileName : '',
        }),
        () => {
          setFetching(false);
          history.push(`/protocol/${id}/test-shipments`);
        },
        undefined,
        t
      );

      return true;
    },
    [dispatchCreateTestShipmentPost, id, t]
  );

  if (!open) return null;

  return (
    <TestShipmentForm
      header={t('createTestShipmentForProtocol', { protocolName: protocol?.route?.name })}
      onSubmit={handleSubmit}
      submitProcessing={createTestShipmentFetch.pending || fetching}
      returnRoute={`/protocol/${id}/test-shipments`}
      cancelRoute={`/protocol/${id}/test-shipments`}
      data={EMPTY_TEST_SHIPMENT}
      protocol={protocol}
    />
  );
};

NewTestShipment.propTypes = {
  dispatchCreateTestShipmentPost: PropTypes.func.isRequired,
  createTestShipmentFetch: PropTypes.object.isRequired,
  protocol: PropTypes.object,
  open: PropTypes.bool,
};

NewTestShipment.defaultProps = { protocol: undefined, open: false };

export default createTestShipmentService(NewTestShipment);
