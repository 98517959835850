import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import { Flex } from 'nallian-shared-ui/lib/components';
import updateProtocolService from '../services/UpdateProtocolService';
import protocolService from '../services/ProtocolService';
import protocolsService from '../services/ProtocolsService';
import ProtocolForm from './ProtocolForm';
import { mapFormToProtocol } from '../helpers/mapFormToProtocol';

const EditProtocol = ({
  open,
  dispatchUpdateProtocolPut,
  updateProtocolFetch,
  dispatchProtocolGet,
  protocolFetch,
  dispatchProtocolsGet,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateProtocolPut(
        id,
        mapFormToProtocol(body),
        () => {
          dispatchProtocolsGet();
          history.push(`/protocols`);
        },
        t
      ),
    [id, dispatchUpdateProtocolPut, dispatchProtocolsGet, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchProtocolGet(id, () => history.push('/protocols'), t);
    }
  }, [id, open, t, dispatchProtocolGet]);

  const defaultData = useMemo(
    () =>
      !protocolFetch.pending && {
        ...(protocolFetch.value || {}),
      },
    [protocolFetch]
  );

  if (!open) return null;

  return (
    <ProtocolForm
      header={
        <Flex alignItems="center">
          {t('protocolForRoute')}&nbsp;-&nbsp;{protocolFetch.value?.route?.name}
        </Flex>
      }
      onSubmit={handleSubmit}
      submitProcessing={updateProtocolFetch.pending}
      returnRoute="/protocols"
      cancelRoute="/protocols"
      dataLoading={protocolFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditProtocol.propTypes = {
  dispatchProtocolsGet: PropTypes.func.isRequired,
  dispatchUpdateProtocolPut: PropTypes.func.isRequired,
  updateProtocolFetch: PropTypes.object.isRequired,
  dispatchProtocolGet: PropTypes.func.isRequired,
  protocolFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditProtocol.defaultProps = { open: false };

export default compose(protocolsService, protocolService, updateProtocolService)(EditProtocol);
