import reduxFetch from 'react-redux-fetch';
import { UPDATE_AGENT_RESOURCE, AGENTS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const UpdateAgentService = ComposedComponent =>
  reduxFetch([
    {
      resource: UPDATE_AGENT_RESOURCE,
      method: 'put',
      request: (id, body, callback, rejectCallback, t) => ({
        url: apiRoutes.agents(id),
        body,
        meta: {
          callback,
          rejectCallback,
          updateOtherLists: [
            {
              resource: AGENTS_RESOURCE,
              id,
              idProp: '_id',
            },
          ],
          toaster: {
            onErrorMessage: t('updateAgentFailed'),
            onSuccessMessage: t('updateAgentSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default UpdateAgentService;
