/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'nallian-shared-ui/lib/components';
import { downloadFile } from '../helpers/downloadFile';

const DownloadButton = ({ file, ...props }) => {
  const handleDownload = useCallback(() => downloadFile(file), [file]);

  return <Button onClick={handleDownload} {...props} />;
};

DownloadButton.propTypes = { file: PropTypes.string };
DownloadButton.defaultProps = { file: undefined };

export default DownloadButton;
