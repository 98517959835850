import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { UPDATE_PROTOCOL_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const UpdateProtocolService = ComposedComponent =>
  reduxFetch([
    {
      resource: UPDATE_PROTOCOL_RESOURCE,
      method: 'put',
      request: (id, body, callback, t) => ({
        url: apiRoutes.protocols(id),
        body,
        meta: {
          callback,
          toaster: {
            onErrorMessage: createErrorMessage(t, 'updateProtocolFailed'),
            onSuccessMessage: t('updateProtocolSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default UpdateProtocolService;
