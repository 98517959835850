import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Field } from 'nallian-shared-ui/lib/components';
import { useTranslation } from 'react-i18next';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import { InputFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { FormModal } from 'nallian-shared-ui/lib/modules';

const REQUIRED_FIELDS = ['name'];
const FORM_NAME = 'forwarder';

const validate = values => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);

  return errors;
};

const ForwarderForm = ({ submitProcessing, dataLoading, data, returnRoute, onSubmit, header }) => {
  const { t } = useTranslation();

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      cancelRoute={returnRoute}
      cancelButtonContent={t('cancel')}
      saveButtonIcon="save"
      saveButtonContent={t('save')}
    >
      <Grid columns={1}>
        <Grid.Column>
          <Field
            fluid
            label={t('name')}
            name="name"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
      </Grid>
    </FormModal>
  );
};

ForwarderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  data: PropTypes.object,
};

ForwarderForm.defaultProps = {
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default ForwarderForm;
