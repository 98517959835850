import reduxFetch from 'react-redux-fetch';
import { createErrorMessage } from 'nallian-shared-ui/lib/lib/createErrorMessage';
import { CREATE_TEST_SHIPMENT_RESOURCE, TEST_SHIPMENTS_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const CreateTestShipmentService = ComposedComponent =>
  reduxFetch([
    {
      resource: CREATE_TEST_SHIPMENT_RESOURCE,
      method: 'post',
      request: (protocolId, body, callback, rejectCallback, t) => ({
        url: apiRoutes.testShipments(protocolId),
        body,
        meta: {
          addToOtherList: { resource: TEST_SHIPMENTS_RESOURCE },
          callback,
          rejectCallback,
          toaster: {
            onErrorMessage: createErrorMessage(t, 'createTestShipmentFailed'),
            onSuccessMessage: t('createTestShipmentSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default CreateTestShipmentService;
