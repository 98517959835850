import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const TransportModesScene = () => (
  <>
    <Route
      exact
      path="/management/transport-modes/:id?"
      menuName="transportModes_plural"
      component={Overview}
    />
  </>
);

export default TransportModesScene;
