import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateEdlmService from '../services/updateEdlmService';
import edlmService from '../services/edlmService';
import EdlmForm from './EdlmForm';

const EditEdlm = ({ open, dispatchUpdateEdlmPut, updateEdlmFetch, dispatchEdlmGet, edlmFetch }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body => dispatchUpdateEdlmPut(id, body, () => history.push('/management/edlms'), undefined, t),
    [id, dispatchUpdateEdlmPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchEdlmGet(id, () => history.push('/management/edlms'), t);
    }
  }, [id, open, t, dispatchEdlmGet]);

  const defaultData = useMemo(
    () =>
      !edlmFetch.pending && {
        ...(edlmFetch.value || {}),
      },
    [edlmFetch]
  );

  if (!open) return null;

  return (
    <EdlmForm
      header={edlmFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateEdlmFetch.pending}
      returnRoute="/management/edlms"
      cancelRoute="/management/edlms"
      dataLoading={edlmFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditEdlm.propTypes = {
  dispatchUpdateEdlmPut: PropTypes.func.isRequired,
  updateEdlmFetch: PropTypes.object.isRequired,
  dispatchEdlmGet: PropTypes.func.isRequired,
  edlmFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditEdlm.defaultProps = { open: false };

export default compose(edlmService, updateEdlmService)(EditEdlm);
