import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'redux-form';
import { Field, Grid, Divider } from 'nallian-shared-ui/lib/components';
import { InputFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import ActionFields from './ActionFields';

const ResponsibilityField = ({ submitProcessing, field }) => {
  const { t } = useTranslation();

  // const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Field
            fluid
            label={t('responsibility')}
            name={`${field}.step`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <FieldArray name={`${field}.actions`} component={ActionFields} />
        </Grid.Column>
      </Grid>
      <Divider />
    </>
  );
};

ResponsibilityField.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
};

export default ResponsibilityField;
