import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { history } from 'nallian-shared-ui/lib/config/history';
import configureStore from 'nallian-shared-ui/lib/store/configureStore';
import { basename } from 'nallian-shared-ui/lib/lib/path';
import MsalAuthentication from 'nallian-shared-ui/lib/authentication/MsalAuthentication';
import { createMsalInstance } from 'nallian-shared-ui/lib/authentication/helpers/createMsalInstance';
import Layout from 'nallian-shared-ui/lib/layout';
import 'nallian-shared-ui/lib/config/i18n';
import rootReducer, { loadReactReduxFetchExtensions } from './packages/rootReducer';
import rootSaga from './packages/rootSaga';
import routes from './packages/app/config/routes';
import translations from './packages/app/config/translations';

const store = configureStore(rootReducer, rootSaga);
const msalInstance = createMsalInstance();

loadReactReduxFetchExtensions();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} basename={basename}>
      <MsalAuthentication pca={msalInstance}>
        <Layout
          routes={routes}
          applicationName="Lane Risk Management"
          translations={translations}
        />
      </MsalAuthentication>
    </Router>
  </Provider>,
  document.getElementById('root')
);
