import Scene from '../scenes/ProductPhasesScene';

const routes = [
  {
    path: '/management/product-phases',
    menuName: 'productPhases_plural',
    component: Scene,
  },
];

export default routes;
