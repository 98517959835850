import Scene from '../scenes/ForwardersScene';

const routes = [
  {
    path: '/management/forwarders',
    menuName: 'forwarders_plural',
    component: Scene,
  },
];

export default routes;
