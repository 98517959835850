import getUnixTime from 'date-fns/getUnixTime';

export const getClosestDates = (timestamps = [], targetStartDate, targetEndDate) => {
  let closestStartDate = null;
  let closestEndDate = null;

  const targetStartTimestamp = targetStartDate;
  const targetEndTimestamp = targetEndDate;

  let closestStart = timestamps[0];
  let closestEnd = timestamps[0];

  let minStartDifference = Math.abs(timestamps[0] - targetStartTimestamp);
  let minEndDifference = Math.abs(timestamps[0] - targetEndTimestamp);

  timestamps.forEach(timestamp => {
    const startDifference = Math.abs(timestamp - targetStartTimestamp);
    const endDifference = Math.abs(timestamp - targetEndTimestamp);

    if (startDifference < minStartDifference) {
      minStartDifference = startDifference;
      closestStart = timestamp;
    }

    if (endDifference < minEndDifference) {
      minEndDifference = endDifference;
      closestEnd = timestamp;
    }
  });

  closestStartDate = getUnixTime(new Date(closestStart * 1000));
  closestEndDate = getUnixTime(new Date(closestEnd * 1000));

  return [closestStartDate, closestEndDate];
};
