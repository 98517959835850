import reduxFetch from 'react-redux-fetch';
import { PROTOCOLS_RESOURCE } from '../constants';
import apiRoutes from '../../../app/api/routes';

const ProtocolsService = ComposedComponent =>
  reduxFetch([
    {
      resource: PROTOCOLS_RESOURCE,
      method: 'get',
      request: () => ({
        url: apiRoutes.protocols(),
      }),
    },
  ])(ComposedComponent);

export default ProtocolsService;
