import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import createForwarderService from '../services/createForwarderService';
import ForwarderForm from './ForwarderForm';

const EMPTY_FORWARDER = { name: '' };

const NewForwarder = ({ open, dispatchCreateForwarderPost, createForwarderFetch }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    body =>
      dispatchCreateForwarderPost(body, () => history.push('/management/forwarders'), undefined, t),
    [dispatchCreateForwarderPost, t]
  );

  if (!open) return null;

  return (
    <ForwarderForm
      header={t('createForwarder')}
      onSubmit={handleSubmit}
      submitProcessing={createForwarderFetch.pending}
      returnRoute="/management/forwarders"
      cancelRoute="/management/forwarders"
      data={EMPTY_FORWARDER}
    />
  );
};

NewForwarder.propTypes = {
  dispatchCreateForwarderPost: PropTypes.func.isRequired,
  createForwarderFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

NewForwarder.defaultProps = { open: false };

export default createForwarderService(NewForwarder);
