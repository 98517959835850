import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const ProductPackagingsScene = () => (
  <>
    <Route
      exact
      path="/management/product-packagings/:id?"
      menuName="productPackagings_plural"
      component={Overview}
    />
  </>
);

export default ProductPackagingsScene;
