import styled from 'styled-components';
import { Step as SharedStep, Flex, Grid } from 'nallian-shared-ui/lib/components';

export const Step = styled(SharedStep)`
  width: ${props => props.width || '50%'};
  padding-left: 1rem !important;
  padding-right: 0 !important;

  ${props =>
    props.error &&
    `
    color: ${props.theme.stateError} !important;
    && .content .title {
      color: ${props.theme.stateError} !important;
    }
    && .content .description {
      color: ${props.theme.stateError} !important;
    }
  `}
`;

export const StepNumber = styled(Flex)`
  font-size: 40px;
  font-weight: bold;
  margin: -0.3rem 1.5rem 0 0;
  height: 100%;
  align-items: center;
`;

export const Hidden = styled.div`
  ${props =>
    props.hidden &&
    `
    display: none;
  `}
`;

export const FieldFlex = styled(Flex)`
  && .field {
    width: 100%;
  }
`;

export const GridColumn = styled(Grid.Column)`
  padding-top: 0 !important;

  ${props =>
    props.noPaddingBottom &&
    `
    padding-bottom: 0 !important;
  `}

  ${props =>
    props.correctBottom &&
    `
    margin-bottom: -1rem !important;
  `}
`;
