import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import { showToaster } from 'nallian-shared-ui/lib/lib/showToaster';
import { uploadFile } from '../../app/helpers/uploadFile';
import { renameFile } from '../../app/helpers/renameFile';
import updateTestShipmentService from '../services/updateTestShipmentService';
import testShipmentService from '../services/testShipmentService';
import TestShipmentForm from './TestShipmentForm';
import { mapFormToTestShipment } from '../helpers/mapFormToTestShipment';

const EditTestShipment = ({
  open,
  dispatchUpdateTestShipmentPut,
  updateTestShipmentFetch,
  dispatchTestShipmentGet,
  testShipmentFetch,
  protocol,
}) => {
  const { t } = useTranslation();
  const { id, subResourceId } = useParams();
  const [fetching, setFetching] = useState(false);

  const handleSubmit = useCallback(
    async body => {
      const { loadingDocument, edlmDataDocument, ...restBody } = body;

      const loadingDocumentFileName = `protocols/${id}/test-shipments/loading-document/${loadingDocument?.name}`;
      const edlmDataDocumentFileName = `protocols/${id}/test-shipments/edlm-data/${edlmDataDocument?.name}`;
      setFetching(true);
      if (loadingDocument?.name) {
        const uploadSuccess = await uploadFile(
          renameFile(loadingDocument, loadingDocumentFileName)
        );
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedUpdateTestShipment'));
          return false;
        }
      }
      if (edlmDataDocument?.name) {
        const uploadSuccess = await uploadFile(
          renameFile(edlmDataDocument, edlmDataDocumentFileName)
        );
        if (!uploadSuccess) {
          setFetching(false);
          showToaster('error', t('fileUploadFailedAndAbortedUpdateTestShipment'));
          return false;
        }
      }
      dispatchUpdateTestShipmentPut(
        id,
        subResourceId,
        mapFormToTestShipment({
          ...restBody,
          loadingDocument: loadingDocument?.name ? loadingDocumentFileName : loadingDocument || '',
          edlmDataDocument: edlmDataDocument?.name
            ? edlmDataDocumentFileName
            : edlmDataDocument || '',
        }),
        () => {
          setFetching(false);
          history.push(`/protocol/${id}/test-shipments`);
        },
        undefined,
        t
      );

      return true;
    },
    [id, subResourceId, setFetching, dispatchUpdateTestShipmentPut, t]
  );

  useEffect(() => {
    if (open && subResourceId && id) {
      dispatchTestShipmentGet(
        id,
        subResourceId,
        () => history.push(`/protocol/${id}/test-shipments`),
        t
      );
    }
  }, [id, subResourceId, open, t, dispatchTestShipmentGet]);

  const defaultData = useMemo(
    () => !testShipmentFetch.pending && testShipmentFetch.value,
    [testShipmentFetch]
  );

  if (!open) return null;

  return (
    <TestShipmentForm
      header={t('updateTestShipmentForProtocol', { protocolName: protocol?.route?.name })}
      onSubmit={handleSubmit}
      submitProcessing={updateTestShipmentFetch.pending || fetching}
      returnRoute={`/protocol/${id}/test-shipments`}
      cancelRoute={`/protocol/${id}/test-shipments`}
      dataLoading={testShipmentFetch.pending}
      data={defaultData}
      protocol={protocol}
      showRemoveButton
      editMode
    />
  );
};

EditTestShipment.propTypes = {
  dispatchUpdateTestShipmentPut: PropTypes.func.isRequired,
  updateTestShipmentFetch: PropTypes.object.isRequired,
  dispatchTestShipmentGet: PropTypes.func.isRequired,
  testShipmentFetch: PropTypes.object.isRequired,
  protocol: PropTypes.object,
  open: PropTypes.bool,
};

EditTestShipment.defaultProps = { protocol: undefined, open: false };

export default compose(testShipmentService, updateTestShipmentService)(EditTestShipment);
