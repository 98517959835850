import reduxFetch from 'react-redux-fetch';
import { UPDATE_CUSTOMER_RESOURCE, CUSTOMERS_RESOURCE } from '../constants';
import apiRoutes from '../../../../app/api/routes';

const UpdateCustomerService = ComposedComponent =>
  reduxFetch([
    {
      resource: UPDATE_CUSTOMER_RESOURCE,
      method: 'put',
      request: (id, body, callback, rejectCallback, t) => ({
        url: apiRoutes.customers(id),
        body,
        meta: {
          callback,
          rejectCallback,
          updateOtherLists: [
            {
              resource: CUSTOMERS_RESOURCE,
              id,
              idProp: '_id',
            },
            {
              // lookup field
              resource: 'customerId',
              id,
              idProp: '_id',
            },
          ],
          toaster: {
            onErrorMessage: t('updateCustomerFailed'),
            onSuccessMessage: t('updateCustomerSuccess'),
          },
        },
      }),
    },
  ])(ComposedComponent);

export default UpdateCustomerService;
