import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MONTH_OPTIONS } from '../../../protocols/constants';

const Month = ({ value }) => {
  const month = useMemo(() => MONTH_OPTIONS.find(m => m.value === value)?.text, [value]);
  return <span>{month}</span>;
};

Month.propTypes = { value: PropTypes.string.isRequired };

export default Month;
