import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const ForwardersScene = () => (
  <>
    <Route
      exact
      path="/management/forwarders/:id?"
      menuName="forwarders_plural"
      component={Overview}
    />
  </>
);

export default ForwardersScene;
