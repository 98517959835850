import Scene from '../scenes/LaneTypesScene';

const routes = [
  {
    path: '/management/lane-types',
    menuName: 'laneTypes_plural',
    component: Scene,
  },
];

export default routes;
