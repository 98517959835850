import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { history } from 'nallian-shared-ui/lib/config/history';
import updateAgentService from '../services/updateAgentService';
import agentService from '../services/agentService';
import AgentForm from './AgentForm';

const EditAgent = ({
  open,
  dispatchUpdateAgentPut,
  updateAgentFetch,
  dispatchAgentGet,
  agentFetch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = useCallback(
    body =>
      dispatchUpdateAgentPut(id, body, () => history.push('/management/agents'), undefined, t),
    [id, dispatchUpdateAgentPut, t]
  );

  useEffect(() => {
    if (open && id) {
      dispatchAgentGet(id, () => history.push('/management/agents'), t);
    }
  }, [id, open, t, dispatchAgentGet]);

  const defaultData = useMemo(
    () =>
      !agentFetch.pending && {
        ...(agentFetch.value || {}),
      },
    [agentFetch]
  );

  if (!open) return null;

  return (
    <AgentForm
      header={agentFetch.value?.name}
      onSubmit={handleSubmit}
      submitProcessing={updateAgentFetch.pending}
      returnRoute="/management/agents"
      cancelRoute="/management/agents"
      dataLoading={agentFetch.pending}
      data={defaultData}
      showRemoveButton
      editMode
    />
  );
};

EditAgent.propTypes = {
  dispatchUpdateAgentPut: PropTypes.func.isRequired,
  updateAgentFetch: PropTypes.object.isRequired,
  dispatchAgentGet: PropTypes.func.isRequired,
  agentFetch: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

EditAgent.defaultProps = { open: false };

export default compose(agentService, updateAgentService)(EditAgent);
