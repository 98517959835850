import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Field } from 'nallian-shared-ui/lib/components';
import { useTranslation } from 'react-i18next';
import { generateErrorsForRequiredFields } from 'nallian-shared-ui/lib/lib/validate';
import { InputFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import { FormModal } from 'nallian-shared-ui/lib/modules';

const REQUIRED_FIELDS = ['name', 'company', 'url'];
const FORM_NAME = 'productPackaging';

const validate = values => {
  let errors = {};
  errors = generateErrorsForRequiredFields(REQUIRED_FIELDS, values, errors);

  return errors;
};

const ProductPackagingForm = ({
  submitProcessing,
  dataLoading,
  data,
  returnRoute,
  onSubmit,
  header,
}) => {
  const { t } = useTranslation();

  return (
    <FormModal
      closeIcon
      header={header}
      dataLoading={dataLoading}
      submitProcessing={submitProcessing}
      onSubmit={onSubmit}
      data={data}
      t={t}
      validate={validate}
      form={FORM_NAME}
      cancelRoute={returnRoute}
      cancelButtonContent={t('cancel')}
      saveButtonIcon="save"
      saveButtonContent={t('save')}
    >
      <Grid>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            label={t('name')}
            name="name"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            label={t('company')}
            name="company"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Field
            fluid
            label={t('url')}
            name="url"
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing || dataLoading}
          />
        </Grid.Column>
      </Grid>
    </FormModal>
  );
};

ProductPackagingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  submitProcessing: PropTypes.bool,
  header: PropTypes.string.isRequired,
  returnRoute: PropTypes.string.isRequired,
  data: PropTypes.object,
};

ProductPackagingForm.defaultProps = {
  dataLoading: false,
  submitProcessing: false,
  data: {},
};

export default ProductPackagingForm;
