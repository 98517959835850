import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'nallian-shared-ui/lib/components';
import { InputFieldValueComponent } from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';

const ActionField = ({ submitProcessing, field, index }) => {
  const { t } = useTranslation();

  // const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <>
      <Field
        fluid
        label={index <= 0 && t('action')}
        name={`${field}.action`}
        valueComponent={InputFieldValueComponent}
        disabled={submitProcessing}
      />
    </>
  );
};

ActionField.propTypes = {
  submitProcessing: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default ActionField;
