import Scene from '../scenes/AgentsScene';

const routes = [
  {
    path: '/management/agents',
    menuName: 'agents_plural',
    component: Scene,
  },
];

export default routes;
