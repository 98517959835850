import React from 'react';
import { Route } from 'react-router-dom';
import Overview from '../components/Overview';

const EdlmsScene = () => (
  <>
    <Route exact path="/management/edlms/:id?" menuName="edlms_plural" component={Overview} />
  </>
);

export default EdlmsScene;
