import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import lookupService from 'nallian-shared-ui/lib/hoc/lookupService';
import { Field, Grid, Button, Segment } from 'nallian-shared-ui/lib/components';
import {
  InputFieldValueComponent,
  SelectFieldValueComponent,
  DatepickerFieldValueComponent,
} from 'nallian-shared-ui/lib/components/redux-form-semantic-ui';
import apiRoutes from '../../../../../app/api/routes';

const MilestoneField = ({ submitProcessing, field, fields, index, timezonesFetch }) => {
  const { t } = useTranslation();

  const timezoneOptions = useMemo(
    () => (timezonesFetch.value || []).map(v => ({ key: v, value: v, text: v })),
    [timezonesFetch.value]
  );

  const handleRemoveFieldClick = useCallback((_, { i }) => fields.remove(i), [fields]);

  return (
    <Segment secondary>
      <Grid>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            search
            clearable
            label={t('name')}
            name={`${field}.name`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            search
            clearable
            label={t('companyName')}
            name={`${field}.companyName`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            search
            clearable
            label={t('iata')}
            name={`${field}.iata`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            search
            clearable
            label={t('location')}
            name={`${field}.location`}
            valueComponent={InputFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            showTimeSelect
            dateFormat="dd-MM-yyyy HH:mm"
            label={t('timestamp')}
            name={`${field}.timestamp`}
            valueComponent={DatepickerFieldValueComponent}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={8} computer={8}>
          <Field
            fluid
            search
            label={t('timeZone')}
            name={`${field}.timezone`}
            valueComponent={SelectFieldValueComponent}
            options={timezoneOptions}
            disabled={submitProcessing}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" mobile={16} tablet={16} computer={16}>
          <Button
            iconbutton
            linkbutton
            content={t('removeMilestone')}
            icon="close"
            type="button"
            id="removeIdCode"
            tabindex="-1"
            i={index}
            onClick={handleRemoveFieldClick}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

MilestoneField.propTypes = {
  timezonesFetch: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  submitProcessing: PropTypes.bool.isRequired,
  field: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
};

export default lookupService({
  resource: 'timezones',
  url: apiRoutes.timezones(),
})(MilestoneField);
