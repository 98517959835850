import protocolRoutes from '../../protocols/routes';
import routeRoutes from '../../routes/routes';
import customerRoutes from '../../management/customers/routes';
import edlmRoutes from '../../management/edlms/routes';
import laneTypeRoutes from '../../management/laneTypes/routes';
import packagingRoutes from '../../management/packagings/routes';
import productPackagingRoutes from '../../management/productPackagings/routes';
import productPhasesRoutes from '../../management/productPhases/routes';
import productRoutes from '../../management/products/routes';
import transitTypeRoutes from '../../management/transitTypes/routes';
import transportModes from '../../management/transportModes/routes';
import agentRoutes from '../../management/agents/routes';
import forwarderRoutes from '../../management/forwarders/routes';
import productTypeRoutes from '../../management/productTypes/routes';
// import Template from '../components/Template';

const routes = [
  {
    title: 'Main menu',
    icon: 'home',
    id: 'default',
    routes: [
      ...routeRoutes,
      ...protocolRoutes,
      // {
      //   path: '/upload-test-shipments-info',
      //   menuName: 'Upload test shipments information',
      //   component: Template,
      // },
      // {
      //   path: '/final-lane-risk-test-report',
      //   menuName: 'Final Lane risk test report',
      //   component: Template,
      // },
      // {
      //   path: '/upload-regular-shipment-info',
      //   menuName: 'Upload regular shipment information',
      //   component: Template,
      // },
      // {
      //   path: '/dashboards',
      //   menuName: 'dashboards',
      //   routes: [
      //     {
      //       path: '/dashboards/methodology',
      //       menuName: 'Methodology',
      //       component: Template,
      //     },
      //     {
      //       path: '/dashboards/analysis',
      //       menuName: 'Lane risk analysis report',
      //       component: Template,
      //     },
      //     {
      //       path: '/dashboards/route-selection',
      //       menuName: 'Route selection request',
      //       component: Template,
      //     },
      //     {
      //       path: '/dashboards/performance',
      //       menuName: 'Performance reporting',
      //       component: Template,
      //     },
      //   ],
      // },
      {
        path: '/management',
        menuName: 'configuration',
        routes: [
          ...agentRoutes,
          ...customerRoutes,
          ...edlmRoutes,
          ...forwarderRoutes,
          ...laneTypeRoutes,
          ...packagingRoutes,
          ...productPackagingRoutes,
          ...productPhasesRoutes,
          ...productRoutes,
          ...productTypeRoutes,
          ...transitTypeRoutes,
          ...transportModes,
        ],
      },
    ],
  },
];

export default routes;
